import React from 'react';
import PropTypes from 'prop-types';

const LoadMore = ({ onClick, hasMore, isLoading }) => {
  let content = null;
  if (isLoading) {
    content = (
      <div>
        <span className="loader" />
      </div>
    );
  } else if (hasMore) {
    content = <button onClick={onClick}>Load More</button>;
  }

  return <div className="load-more">{content}</div>;
};

LoadMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadMore;

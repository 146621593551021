/* Polyfills */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import store from './store';
import getUserConfirmation from 'components/global/getUserConfirmation';
import 'scss/index.scss';

ReactDOM.render(
  <BrowserRouter getUserConfirmation={getUserConfirmation}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

const Input = props => {
  return (
    <>
      <input {...props} data-tip={props.value} />
      <ReactTooltip
        place="bottom"
        effect="solid"
        data-event="mouseenter"
        data-event-off="mouseleave"
        data-multiline={true}
      />
    </>
  );
};

Input.propTypes = {
  value: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};

const TSelect = props => {
  let select = null;
  const { tooltip, ...rest } = props;
  return (
    <div
      ref={el => (select = el)}
      data-tip={tooltip}
      onMouseEnter={() => ReactTooltip.show(select)}
      onMouseLeave={() => ReactTooltip.hide(select)}
    >
      <Select {...rest} />
      <ReactTooltip place="bottom" effect="solid" data-multiline={true} />
    </div>
  );
};

TSelect.propTypes = {
  tooltip: PropTypes.string,
};

export { Input, TSelect };

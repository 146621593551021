import { withAuth, dispatchWithAuth } from 'actions';

export function fetchCategories(keyId, success) {
  const url = `/documents/${keyId}/folders/summary`;
  return dispatch => {
    dispatch({ type: 'RESET_CATEGORIES', action: null });
    dispatchWithAuth(dispatch, success)({ url, keyId }, 'FETCH_CATEGORIES');
  };
}

export function searchDocuments(params, data, success) {
  const url = '/search';
  return dispatch => {
    dispatchWithAuth(dispatch, success)(
      { method: 'post', url, params, data },
      'FETCH_DOCUMENT_RESULTS'
    );
  };
}

export function fetchDocuments(
  keyId,
  folderSlug,
  category,
  params,
  success,
  failure
) {
  const url = `/documents/${keyId}/byfolder/${folderSlug}`;
  if (category) params.category = category;
  params.take = 'all';

  return dispatch => {
    if (params.skip === 0) dispatch({ type: 'RESET_DOCUMENTS', action: null });
    dispatchWithAuth(dispatch, success, failure)(
      { url, params },
      'FETCH_DOCUMENTS'
    );
  };
}

export function fetchDocument(keyId, folderSlug, documentId) {
  const url = `/documents/${keyId}/${documentId}`;
  const resetPayload = { keyId, folderSlug, documentId };
  return dispatch => {
    dispatch({ type: 'RESET_DOCUMENT', payload: resetPayload });
    dispatchWithAuth(dispatch)({ url }, 'FETCH_DOCUMENT');
  };
}

export function deleteDocument(keyId, documentId, success, failure) {
  const url = `/documents/${keyId}/${documentId}`;
  return withAuth({ method: 'delete', url }, success, failure);
}

export function modifyDocument(keyId, documentId, data, success, failure) {
  const url = `/documents/${keyId}/${documentId}/modify`;
  return withAuth({ method: 'put', url, data }, success, failure);
}

export function fetchWatchListStatus(keyId) {
  const url = `/watchList/${keyId}`;
  return dispatch => dispatchWithAuth(dispatch)({ url }, 'FETCH_APPID_WATCH');
}

export function fetchSas(success) {
  const url = 'setup/sas';
  return dispatch => dispatchWithAuth(dispatch, success)({ url }, 'FETCH_SAS');
}

export function generatePreview(doc, success, failure) {
  const url = `/documents/${doc.keyId}/${doc.id}/preview`;
  return withAuth(
    { method: 'get', url },
    resp => success(resp.data),
    error => failure(error)
  );
}

export function fetchSetupCategories() {
  const url = '/setup/categories';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_SETUP_CATEGORIES');
}

export function fetchSetupSubCategories() {
  const url = '/setup/subcategories';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_SETUP_SUBCATEGORIES');
}

export function fetchSetupURATypes() {
  const url = '/setup/uraTypes';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_SETUP_URA_TYPES');
}

export function fetchSetupFolders() {
  const url = '/setup/folders';
  return dispatch => dispatchWithAuth(dispatch)({ url }, 'FETCH_SETUP_FOLDERS');
}

export function fetchSetupDocumentTypes() {
  const url = '/setup/documentTypes';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_SETUP_DOCUMENT_TYPES');
}

export function checkExists(keyId, data, success, failure) {
  const url = `/documents/${keyId}/checkExists`;
  return withAuth(
    { method: 'post', url, data },
    resp => success(resp.data),
    error => failure(error)
  );
}

export function postDocument(keyId, data, success, failure) {
  const url = `/documents/${keyId}`;
  return withAuth(
    { method: 'post', url, /* headers,*/ data },
    success,
    failure
  );
}

export function putDocument(keyId, doc, success, failure) {
  const url = `/documents/${keyId}/${doc.id}`;
  return withAuth({ method: 'put', url, data: doc }, success, failure);
}

export function splitDocument(keyId, data, success, failure) {
  const url = `/documents/${keyId}/split`;
  return withAuth({ method: 'post', url, data }, success, failure);
}

export function mergeDocuments(keyId, data, success, failure) {
  const url = `/documents/${keyId}/merge`;
  return withAuth({ method: 'post', url, data }, success, failure);
}

export function addToViewedDocuments(documentId) {
  return dispatch =>
    dispatch({ type: 'ADD_TO_VIEWED_DOCUMENTS', data: documentId });
}

export function fetchDocumentPages(keyId, documentId, success) {
  const url = `/documents/${keyId}/${documentId}/pages`;
  return withAuth({ url }, resp => success(resp.data));
}

export function fetchApplicationInfo(appId){
  console.log(appId)
  const url =`/Applications/${appId}`;
  return dispatch => dispatchWithAuth(dispatch)({url},'FETCH_APPLICATION_INFO');
}
import { getLocal, setLocal } from 'utils/storage';

const KEY = 'viewedDocuments';

function getViewedDocuments() {
  const ids = JSON.parse(getLocal(KEY, '[]'));
  return new Set(ids);
}

function setViewedDocuments(ids) {
  setLocal(KEY, JSON.stringify(ids));
  return new Set(ids);
}

export {
  getViewedDocuments,
  setViewedDocuments,
};

const defaultState = {
  statusCode: 0,
  metrics: {
    totalKeyIds: 0,
    totalProcessedDocuments: 0,
    mostUsedDocumentTypes: [],
    totalSearchPast30Days: 0,
    totalDocumentViews: 0,
  },
  watchList: {
    count: 0,
    entries: [],
  },
  savedSearches: {
    count: 0,
    entries: [],
  },
  auditLogs: {
    count: 0,
    entries: [],
    metadata: {},
  },
  recentDocuments: {
    count: 0,
    entries: [],
    metadata: {},
  },
  searchResults: {
    count: 0,
    entries: [],
    metadata: {},
  },
  claims: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    privileges: {
      hasAdminPermissions: false,
      adminUrl: ""
    }
  },
  lastSearchQuery: '',
};

function digestEntries(data) {
  return {
    count: data.count,
    entries: [...data.entries],
    metadata: data.metadata,
  };
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'AUTH_SUCCESS':
      return {
        ...state,
        statusCode: action.data,
      };

    case 'FETCH_METRICS':
      return {
        ...state,
        metrics: { ...action.payload.data },
      };

    case 'FETCH_WATCHLIST':
      return {
        ...state,
        watchList: digestEntries(action.payload.data),
      };

    case 'FETCH_SAVED_SEARCHES':
      return {
        ...state,
        savedSearches: digestEntries(action.payload.data),
      };

    case 'FETCH_RECENT_DOCUMENTS':
      return {
        ...state,
        recentDocuments: digestEntries(action.payload.data),
      };

    case 'FETCH_AUDIT_LOGS':
      return {
        ...state,
        auditLogs: digestEntries(action.payload.data),
      };

    case 'FETCH_SEARCH_RESULTS': {
      return {
        ...state,
        lastSearchQuery: action.params.query,
        searchResults: action.payload.data.count > 0 ? action.payload.data : state.searchResults
      };
    }

    case 'RESET_SEARCH_RESULTS': {
      return {
        ...state,
        searchResults: { ...defaultState.searchResults },
      };
    }

    case 'UPDATE_SEARCH_WATCH': {
      const { keyId, inWatchList } = action.payload;
      const { entries, ...rest } = state.searchResults;
      return {
        ...state,
        searchResults: {
          ...rest,
          entries: entries.map(result => {
            if (keyId !== result.keyId) return result;
            return { ...result, inWatchList };
          }),
        },
      };
    }

    case 'SET_CLAIMS': {
      return {
        ...state,
        claims: action.data,
      };
    }

    default:
      return state;
  }
};

import React from 'react';

import logo from 'images/Logo-BIB.png';

const Footer = () => (
  <div className="container-fluid footer">
    <div className="row">
      <div className="col s12">
        <img src={logo} alt="Footer logo" />
        <span>
          Copyright &copy; {(new Date()).getFullYear()} NYC Build It Back.
          All rights reserved
        </span>
      </div>
    </div>
  </div>
);

export default Footer;

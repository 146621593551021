import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';

import {
  fetchSetupDocumentTypes,
  fetchSetupCategories,
  fetchSetupSubCategories,
  fetchSetupFolders,
  fetchSetupURATypes,
  fetchCategories,
  fetchDocument,
  fetchDocuments,
  putDocument,
  splitDocument,
  mergeDocuments,
} from 'actions/appsActions';
import { getDocPrivileges } from 'utils/privileges';
import ERRORS from './errors';
import _ from 'utils/fp';

import IconButton from 'components/global/IconButton';
import Hamburger from 'components/global/Hamburger';
import DocumentPreview from './DocumentPreview';
import DocumentHeading from './DocumentHeading';
import Form from './Form';


function splitIds(ids) {
  return ids.split('-').map(id => parseInt(id, 10));
}

class DocumentForm extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,

    keyId: PropTypes.string.isRequired,
    folderSlug: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,

    document: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    privileges: PropTypes.object.isRequired,
    categoriesLoading: PropTypes.bool.isRequired,
    documentTypes: PropTypes.array.isRequired,
    documentCategories: PropTypes.array.isRequired,
    documentSubCategories: PropTypes.array.isRequired,
    rootFolders: PropTypes.array.isRequired,
    uraTypes: PropTypes.array.isRequired,
    refIdCounts: PropTypes.object.isRequired,

    fetchDocument: PropTypes.func.isRequired,
    fetchSetupDocumentTypes: PropTypes.func.isRequired,
    fetchSetupCategories: PropTypes.func.isRequired,
    fetchSetupSubCategories: PropTypes.func.isRequired,
    fetchSetupFolders: PropTypes.func.isRequired,
    fetchSetupURATypes: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    toggleSideMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showTick: false,
      errorText: '',
      tenantIds:[]
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.fetchDocument(this.props);
    const {
      documentTypes,
      documentCategories,
      rootFolders,
      uraTypes,
      documentSubCategories,
    } = this.props;
    if (!documentTypes.length) this.props.fetchSetupDocumentTypes();
    if (!documentCategories.length) this.props.fetchSetupCategories();
    if (!documentSubCategories.length) this.props.fetchSetupSubCategories();
    if (!rootFolders.length) this.props.fetchSetupFolders();
    if (!uraTypes.length) this.props.fetchSetupURATypes();
    this.fetchTenantIds();
  }
  fetchTenantIds(){
    const {keyId} = this.props;
    this.props.fetchDocuments(keyId,'application','uniform-relocation-assistance',{},(e)=>{
      this.setState({tenantIds:e.data.metadata.uraTenantIds});
    },(e)=>{
      console.log(e)
    })
  }
  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  fetchDocument({ keyId, folderSlug, documentId }) {
    this.props.fetchDocument(keyId, folderSlug, documentId);
  }

  getNextUrl(doc) {
    const { keyId, rootFolders, documentCategories } = this.props;
    const library = _.findById(rootFolders, doc.rootFolderId);
    let listUrl = `/documents/${keyId}/${library.slug}/`;

    if (library.slug === 'application') {
      const category = _.findById(documentCategories, doc.documentCategoryId);
      listUrl = listUrl + category.slug + '/';
    }

    return listUrl;
  }

  onSuccess = () => {};

  saveDocument = () => {
    const { keyId, location } = this.props;
    const doc = this.Form.getUpdated();
    if (doc === null) return;

    this.setState({ isLoading: true });

    const onSuccess = () => {
      
      this.setState({ showTick: true, isLoading: false });
      this.props.fetchCategories(keyId);
      this.props.fetchDocument(this.props.keyId,this.props.folderSlug,this.props.documentId);//fetches new edited document 
      
      
      // Navigate to the new/same folder after Save is successful
      // We are waiting for a second here so the user sees the tick mark on success
      
      this.timeout = setTimeout(() => {
        this.props.history.push(this.getNextUrl(doc));
        
      }, 500);
    };

    const params = qs.parse(location.search.slice(1));

    if (params.split) {
      const payload = {
        sourceDocumentId: doc.id,
        destinationDocumentId: 0,
        startPageNumber: parseInt(params.startPageNumber, 10),
        endPageNumber: parseInt(params.endPageNumber, 10),
        resultDocument: doc,
      };
      splitDocument(keyId, payload, onSuccess, this.notifyError);
    } else if (params.merge) {
      const mergeIds = splitIds(params.mergeIds);
      const payload = {
        sourceDocuments: mergeIds,
        resultDocument: doc,
      };
      mergeDocuments(keyId, payload, onSuccess, this.notifyError);
    } else {
      putDocument(keyId, doc, onSuccess, this.notifyError);
    }
  };

  notifyError = error => {
    let errorText = _.get(error, 'response.data.message');
    if (!errorText) errorText = ERRORS['genericEdit'];
    this.setState({
      errorText,
      isLoading: false,
    });
  };

  closeModal = () => {
    this.setState({
      isLoading: false,
      errorText: '',
    });
  };

  renderErrorModal() {
    const { errorText } = this.state;
    if (!errorText) return null;
    return (
      <div className="modal alert-modal">
        <div className="modal-fade" />
        <div className="modal-content">
          <p>{errorText}</p>
          <button onClick={this.closeModal}>Close</button>
        </div>
      </div>
    );
  }

  renderError(errorText) {
    return (
      <div className="document-detail">
        <div className="col s12 text-center">
          <div className="no-results">{errorText}</div>
        </div>
      </div>
    );
  }

  closeView = () => {
    const { keyId, folderSlug, history } = this.props;
    const backUrl = window.lastListPath || `/documents/${keyId}/${folderSlug}`;
    history.push(backUrl);
  };

  renderHeader() {
    const { toggleSideMenu, document: doc } = this.props;
    return (
      <div className="document-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col s8 scrollable">
              <Hamburger onClick={() => toggleSideMenu(true)} />
              <DocumentHeading doc={doc} />
            </div>
            <div className="col s4 actions">
              {this.state.isLoading ? (
                <button className="btn-load">
                  <span className="loader" />
                </button>
              ) : this.state.showTick ? (
                <span className="tick">&#10004;</span>
              ) : (
                <button className="btn-primary" onClick={this.saveDocument}>
                  Save
                </button>
              )}
              <IconButton
                icon="Close"
                className="btn-action"
                onClick={this.closeView}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPreview() {
    const { location, document } = this.props;
    const params = qs.parse(location.search.slice(1));
    const props = { document };

    if (params.split) {
      props.splitStart = parseInt(params.startPageNumber, 10);
      props.splitEnd = parseInt(params.endPageNumber, 10);
    } else if (params.merge) {
      props.mergeIds = splitIds(params.mergeIds);
    } else if (params.reorder) {
      props.reorder = true;
    }

    return (
      <div className="document-preview">
        <DocumentPreview {...props} />
      </div>
    );
  }

  renderForm() {
    const { rootFolders, documentCategories } = this.props;

    // hard-code restricted folders here
    const filteredFolders = rootFolders.filter(folder => {
      return folder.slug !== 'portal-document-review';
    });

    // users can't move documents to manual-classification
    const filteredCategories = documentCategories.filter(category => {
      return category.slug !== 'manual-classification';
    });


    return (
      <div className="document-metadata">
        <Form
          ref={form => (this.Form = form)}
          doc={this.props.document}
          privileges={this.props.privileges}
          rootFolders={filteredFolders}
          documentCategories={filteredCategories}
          documentSubCategories={this.props.documentSubCategories}
          documentTypes={this.props.documentTypes}
          uraTypes={this.props.uraTypes}
          tenantIds={this.state.tenantIds}
          refIdCounts={this.props.refIdCounts}
          currentUser={this.props.currentUser}
        />
      </div>
    );
  }

  render() {
    const {
      document: doc,
      privileges,
      documentId,
      categoriesLoading,
    } = this.props;
    const showDoc = doc.id === parseInt(documentId, 10);
   
    if (doc.hroClassified && !privileges.canEditClassifyMetadata) {
      return this.renderError(ERRORS.alreadyClassified);
    } else if (!categoriesLoading && !privileges.canEditBasicMetadata) {
      return this.renderError(ERRORS.noEditPermissions);
    }

    return (
      <div className="document-detail">
        {showDoc ? this.renderHeader() : null}
        {this.renderErrorModal()}

        <div className="document-main">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div
                className="col s12 m4 pull-right"
                ref={el => (this.sidebar = el)}
              >
                {showDoc ? this.renderForm() : null}
              </div>
              <div className="col s12 m8">
                {showDoc ? this.renderPreview() : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, { folderSlug }) {
  const { document: doc, privileges } = state.appStore;
  return {
    privileges: getDocPrivileges(privileges, folderSlug, doc),
    categoriesLoading: state.appStore.categoriesLoading,
    document: doc,
    documentTypes: state.appStore.documentTypes,
    documentCategories: state.appStore.documentCategories,
    documentSubCategories: state.appStore.documentSubCategories,
    rootFolders: state.appStore.rootFolders,
    uraTypes: state.appStore.uraTypes,
    refIdCounts: state.appStore.refIdCounts,
    currentUser: state.homeStore.claims,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchDocument,
    fetchSetupDocumentTypes,
    fetchSetupCategories,
    fetchSetupSubCategories,
    fetchSetupFolders,
    fetchSetupURATypes,
    fetchCategories,
    fetchDocuments
  }
)(DocumentForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import DocumentList from './DocumentList';
import DocumentResults from './DocumentResults';
import DocumentDetail from './DocumentDetail';
import DocumentForm from './DocumentForm';
import DocumentUploads from './DocumentUploads';

export default class DetailMenu extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    toggleSideMenu: PropTypes.func.isRequired,
    triggerDownload: PropTypes.func.isRequired,
  };

  render() {
    const { toggleSideMenu, triggerDownload } = this.props;
    return (
      <Switch>
        <Route
          exact
          path="/documents/:keyId"
          render={props => {
            return (
              <DocumentList
                history={props.history}
                match={props.match}
                keyId={props.match.params.keyId}
                folderSlug="auto"
                categorySlug="auto"
                onDownload={triggerDownload}
                toggleSideMenu={toggleSideMenu}
              />
            );
          }}
        />
        <Route
          exact
          path="/documents/:keyId/search/:query"
          render={props => (
            <DocumentResults
              history={props.history}
              match={props.match}
              toggleSideMenu={toggleSideMenu}
              onDownload={triggerDownload}
              {...props.match.params}
            />
          )}
        />
        <Route
          exact
          path="/documents/:keyId/:folderSlug/upload/:refId?"
          render={props => (
            <DocumentUploads
              location={props.location}
              {...props.match.params}
            />
          )}
        />
        <Route
          exact
          path="/documents/:keyId/:folderSlug/:categorySlug?"
          render={props => {
            const { keyId, folderSlug, categorySlug } = props.match.params;
            if(folderSlug==="application-closeout"){
              return (
                <DocumentList
                  history={props.history}
                  match={props.match}
                  keyId={keyId}
                  folderSlug="auto"
                  categorySlug="auto"
                  onDownload={triggerDownload}
                  toggleSideMenu={toggleSideMenu}
                />
              )
            }
            return (
              <DocumentList
                history={props.history}
                match={props.match}
                keyId={keyId}
                folderSlug={folderSlug}
                categorySlug={categorySlug || ''}
                onDownload={triggerDownload}
                toggleSideMenu={toggleSideMenu}
              />
            );
          }}
        />
        <Route
          exact
          path="/documents/:keyId/:folderSlug/view/:documentId"
          render={props => {
            const {folderSlug,keyId} = props.match.params;
            if(folderSlug === "application-closeout"){
              return(
                <DocumentList
                  history={props.history}
                  match={props.match}
                  keyId={keyId}
                  folderSlug="auto"
                  categorySlug="auto"
                  onDownload={triggerDownload}
                  toggleSideMenu={toggleSideMenu}
                />
              )
            }
            return(
              <DocumentDetail
                history={props.history}
                match={props.match}
                onDownload={triggerDownload}
                toggleSideMenu={toggleSideMenu}
                {...props.match.params}
              />
            )
          }}
        />
        <Route
          exact
          path="/documents/:keyId/:folderSlug/edit/:documentId"
          render={props => (
            <DocumentForm
              history={props.history}
              location={props.location}
              toggleSideMenu={toggleSideMenu}
              {...props.match.params}
            />
          )}
        />
      </Switch>
    );
  }
}

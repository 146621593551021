import React from 'react';
import PropTypes from 'prop-types';

import { formatDateTime } from 'utils';

const UserDateData = ({ doc }) => (
  <div>
    <div className="control">
      <label>Created On</label>
      <div>{doc.createdDate ? formatDateTime(doc.createdDate) : '-'}</div>
    </div>
    <div className="control">
      <label>Created By</label>
      <div>{doc.createdBy || '-'}</div>
    </div>
    <div className="control">
      <label>Modified On</label>
      <div>{doc.modifiedDate ? formatDateTime(doc.modifiedDate) : '-'}</div>
    </div>
    <div className="control">
      <label>Modified By</label>
      <div>{doc.modifiedBy || '-'}</div>
    </div>
  </div>
);

UserDateData.propTypes = {
  doc: PropTypes.object,
};

export default UserDateData;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import DropZone from 'react-dropzone';

import {
  fetchSetupDocumentTypes,
  fetchSetupCategories,
  fetchSetupSubCategories,
  fetchSetupFolders,
  fetchSetupURATypes,
  fetchCategories,
} from 'actions/appsActions';
import { getPrivileges } from 'utils/privileges';
import _ from 'utils/fp';
import { MAX_UPLOAD_MB, PERMITTED_EXTENSIONS } from 'utils/limits';
import { getFileExtension, shortenName } from 'utils';
import ERRORS from './errors';
import UploadForm from './UploadForm';

class DocumentUploads extends Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    folderSlug: PropTypes.string.isRequired,
    refId: PropTypes.string,
    location: PropTypes.object.isRequired,

    categoriesLoading: PropTypes.bool.isRequired,
    privileges: PropTypes.object.isRequired,
    documentTypes: PropTypes.array.isRequired,
    documentCategories: PropTypes.array.isRequired,
    documentSubCategories: PropTypes.array.isRequired,
    rootFolders: PropTypes.array.isRequired,
    uraTypes: PropTypes.array.isRequired,
    refIdCounts: PropTypes.object.isRequired,

    fetchSetupDocumentTypes: PropTypes.func.isRequired,
    fetchSetupCategories: PropTypes.func.isRequired,
    fetchSetupSubCategories: PropTypes.func.isRequired,
    fetchSetupFolders: PropTypes.func.isRequired,
    fetchSetupURATypes: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  static defaultProps = {
    refId: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploaded: [],
      uploadedCount: 0,
    };
  }

  componentDidMount() {
    const {
      documentTypes,
      documentCategories,
      documentSubCategories,
      rootFolders,
      uraTypes,
    } = this.props;
    if (!documentTypes.length) this.props.fetchSetupDocumentTypes();
    if (!documentCategories.length) this.props.fetchSetupCategories();
    if (!documentSubCategories.length) this.props.fetchSetupSubCategories();
    if (!rootFolders.length) this.props.fetchSetupFolders();
    if (!uraTypes.length) this.props.fetchSetupURATypes();
  }

  onFileCancel = ({ name, size }) => {
    const files = this.state.files.filter(file => {
      return !(file.name === name && file.size === size);
    });
    this.setState({ files });
  };

  onDrop = newFiles => {
    const files = [...this.state.files, ...newFiles];
    this.setState({ files });
  };

  onFileUploaded = doc => {
    const uploaded = [...this.state.uploaded, doc];
    const uploadedCount = this.state.uploadedCount + 1;
    this.setState({ uploaded, uploadedCount });
    this.props.fetchCategories(this.props.keyId);
  };

  openDoc = ({ id, keyId, rootFolder }) => {
    const url = `/documents/${keyId}/${rootFolder.slug}/view/${id}`;
    window.open(url, '_blank');
  };

  closeUploaded = index => {
    const uploaded = this.state.uploaded.filter((_, i) => i !== index);
    this.setState({ uploaded });
  };

  getFolderPrivileges() {
    const { folderSlug, privileges } = this.props;
    return getPrivileges(privileges, folderSlug);
  }

  getRefIdTitle() {
    return this.getFolderPrivileges().refIdDisplayTitle;
  }

  getCategoryTitle(categoryId) {
    for (const category of this.props.documentCategories) {
      if (category.id === categoryId) return category.title;
    }
  }

  renderDoc(doc, index) {
    const { documentName, documentCategoryId, rootFolder } = doc;
    const category = this.getCategoryTitle(documentCategoryId);
    const relatedPath = `${rootFolder.title} / ${category} / ${documentName}`;
    return (
      <div key={doc.id} className="file-error">
        <p>
          {rootFolder.slug === 'application'
            ? `Your document has been successfully uploaded and classified as ${documentName}`
            : relatedPath}
        </p>
        <button onClick={() => this.closeUploaded(index)}>Close</button>
        <button onClick={() => this.openDoc(doc)}>Open in New Tab</button>
      </div>
    );
  }

  renderFile(file) {
    const key = `${file.name}-${file.size}`;
    const sizeInMB = Math.round(file.size / 1024 / 1024);
    const extension = getFileExtension(file.name);
    const shortName = shortenName(file.name);

    if (PERMITTED_EXTENSIONS.indexOf(extension) === -1) {
      return (
        <div key={key} className="file-error">
          <p>
            <strong>{shortName}</strong> - {ERRORS.extension}
          </p>
          <button onClick={() => this.onFileCancel(file)}>Close</button>
        </div>
      );
    }

    if (sizeInMB > MAX_UPLOAD_MB) {
      return (
        <div key={key} className="file-error">
          <p>
            <strong>{shortName}</strong> - {ERRORS.size}
          </p>
          <button onClick={() => this.onFileCancel(file)}>Close</button>
        </div>
      );
    }

    let {
      folderSlug,
      privileges,
      documentCategories,
      refIdCounts,
    } = this.props;

    const rootFolders = this.props.rootFolders.filter(folder => {
      return privileges[folder.slug].canUpload;
    });

    const rootFolder = _.findBy('slug', rootFolders, folderSlug);
    if (rootFolder && rootFolder.slug === 'application') {
      documentCategories = documentCategories
        .filter(category => {
          return category.rootFolderId === rootFolder.id;
        })
        .filter(category => {
          return privileges['apl-' + category.slug].canUpload;
        });
    }

    let refIdOptions = [];
    if (rootFolder) {
      const refIds = _.get(refIdCounts, rootFolder.slug, []) || [];
      refIdOptions = refIds.map(({ type }) => ({ label: type, value: type }));
    }

    const uraCategory = _.findBy(
      'slug',
      documentCategories,
      'uniform-relocation-assistance'
    );
    const uraCategoryId = uraCategory ? uraCategory.id : 0;

    const formProps = {
      key,
      file,
      refIdOptions,
      onCancel: this.onFileCancel,
      onUploaded: this.onFileUploaded,
      refIdDisplayTitle: this.getRefIdTitle(),
      privileges: privileges[folderSlug],
    };

    const allProps = {
      ...formProps,
      ..._.pick(this.props, [
        'keyId',
        'refId',
        'documentTypes',
        'documentSubCategories',
        'uraTypes',
      ]),
      folderSlug,
      rootFolders,
      documentCategories,
      uraCategoryId,
    };
    return <UploadForm {...allProps} />;
  }

  renderPermissionError(errorMessage) {
    if (this.props.categoriesLoading) return null;
    return (
      <div className="main">
        <div className="col s12 text-center">
          <div className="no-results">{errorMessage}</div>
        </div>
      </div>
    );
  }

  renderUploaded() {
    const { uploaded } = this.state;
    if (uploaded.length === 0) return null;
    return (
      <div className="uploaded">
        <h4>Uploaded</h4>
        {uploaded.map((doc, i) => this.renderDoc(doc, i))}
      </div>
    );
  }

  renderFiles(files) {
    if (files.length === 0) return null;

    let parentClass = 'files';
    if (this.props.folderSlug === 'application')
      parentClass += ' application-upload';

    return (
      <div className={parentClass}>
        <div className="upload-header">
          <div className="documentName">File Name</div>
          <div className="documentTitle">Library</div>
          <div className="relatedFolder">Category</div>
          <div className="documentType">Document Type</div>
          <div className="refid-control">{this.getRefIdTitle()}</div>
        </div>
        {files.map(file => this.renderFile(file))}
      </div>
    );
  }

  render() {
    const { files, uploadedCount } = this.state;
    const { refId } = this.props;

    if (!this.getFolderPrivileges().canUpload) {
      return this.renderPermissionError(ERRORS.noUploadPermissions);
    }

    let promptMessage = ERRORS.refIdPrompt;
    let shouldShowPrompt = refId && !uploadedCount;

    if (files.length > 0) {
      promptMessage = ERRORS.prompt;
      shouldShowPrompt = true;
    }

    return (
      <div className="main">
        <Prompt when={!!shouldShowPrompt} message={promptMessage} />
        <div className="container-fluid">
          <div className="row">
            <div className="col s12">
              <div className="upload-area">
                <h4>{'Upload Documents' + (refId ? ` for ${refId}` : '')}</h4>
                <DropZone
                  onDrop={this.onDrop}
                  className="dropzone"
                  activeClassName="dropzone-active"
                  rejectClassName="dropzone-reject"
                >
                  <button className="btn-primary">
                    Select files to upload
                  </button>
                  <br />
                  <div>or drag and drop here</div>
                </DropZone>
                {this.renderUploaded()}
                {this.renderFiles(files)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.appStore.privileges,
    categoriesLoading: state.appStore.categoriesLoading,
    documentCategories: state.appStore.documentCategories,
    documentSubCategories: state.appStore.documentSubCategories,
    documentTypes: state.appStore.documentTypes,
    uraTypes: state.appStore.uraTypes,
    rootFolders: state.appStore.rootFolders,
    refIdCounts: state.appStore.refIdCounts,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchSetupDocumentTypes,
    fetchSetupCategories,
    fetchSetupSubCategories,
    fetchSetupFolders,
    fetchSetupURATypes,
    fetchCategories,
  }
)(DocumentUploads);

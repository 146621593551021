import React from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';

import { getThumbSas } from 'actions/sas';
import { fetchDocumentPages } from 'actions/appsActions';
import IconButton from 'components/global/IconButton';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { getRotateTransform } from 'utils/documents';

const SortableItem = SortableElement(
  ({ page, pageIndex, activePage, setActivePage }) => (
    <div
      className="pdf-thumb"
      data-status={activePage === pageIndex ? 'active' : ''}
      onClick={() => setActivePage(pageIndex)}
    >
      
      <div className='center-align-smallthumbs'>
      {page.url ? (
        <img
          src={page.url}
          alt={`page ${pageIndex}`}
          style={getRotateTransform(page.rotate,page.url)}
        />
      ) : (
        <div className="loading-page">
          <div className="loader" />
        </div>
      )}
      <span>Page {page.pageNumber}</span>
      </div>
      </div>
      
   
  
  )
);

const SortableList = SortableContainer(
  ({ items, activePage, setActivePage }) => {
    /* Need index, pageIndex because SortableItem consumes index */
    return (
      <div>
        {items.map((page, index) => (
          <SortableItem
            key={index}
            index={index}
            pageIndex={index}
            page={page}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        ))}
      </div>
    );
  }
);

export default class DocumentPages extends React.Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    documentId: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    startPage: PropTypes.number,
    endPage: PropTypes.number,
    onPageChange: PropTypes.func,
  };

  static defaultProps = {
    activePage: 0,
    startPage: 1,
    endPage: Infinity,
    onPageChange: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      pages: [],
    };
    this.timeout = null;
    this.retryCount = 0;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setThumbsPreview();
    this.fetchPages();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.timeout) clearTimeout(this.timeout);
  }

  fetchPages = () => {
    const { keyId, documentId } = this.props;
    
    fetchDocumentPages(keyId, documentId, ({ entries, count }) => {
      this.setState({ pages: entries });

      const rendered = entries.filter(page => page.largeThumbnailUrl);
      
      if (rendered.length < count) {
        this.timeout = setTimeout(this.fetchPages, 4000);
      } else {
        this.setActivePage(0);
      }
    });
  };

  setThumbsPreview() {
    getThumbSas().then(() => {
      if (!this._isMounted) return;
    });
  }

  getPageItems() {
    const { startPage, endPage, onPageChange } = this.props;
    const { pages } = this.state;
    const thumbKey = (onPageChange ? 'small' : 'large') + 'ThumbnailUrl';

    // Lowest of Infinity or splitEnd or pages.count
    const firstPage = startPage - 1;
    const lastPage = Math.min(endPage, pages.length);

    return pages
      .filter((page, num) => num >= firstPage && num < lastPage)
      .map(page => ({
        url: page[thumbKey] && page[thumbKey] ,
        rotate: page.rotate,
        pageNumber: page.pageNumber,
      }));
  }

  changePage = page => {
    const { pages } = this.state;
    this.props.onPageChange({ page, pages });
  };

  setActivePage = index => {
    const { pages } = this.state;
    if (index < pages.length) {
      this.setState({ activePage: index });
      this.changePage(pages[index]);
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { pages } = this.state;
    const newPages = arrayMove(pages, oldIndex, newIndex).map(
      (page, index) => ({ ...page, newPageNumber: index + 1 })
    );

    this.setState(
      { pages: newPages },
      () => this.setActivePage(newIndex) // callback
    );
  };

  rotatePage(change) {
    const { activePage, pages } = this.state;
    const newPages = pages.map((page, index) => {
      
      if (index === activePage) {
        const rotate = ((page.rotate || 0) + 90 * change + 360) % 360;
        
        return { ...page, rotate };
      }
      return page;
    });
    this.setState(
      { pages: newPages },
      () => this.setActivePage(activePage) // callback
    );
  }

  renderSortableSmallThumbs() {
    const { activePage } = this.state;
    return (
      <>
        <div className="pages-toolbar">
          <IconButton
            icon="Rotate-Right"
            className="btn-action"
            onClick={() => this.rotatePage(1)}
          />
          <IconButton
            icon="Rotate-Left"
            className="btn-action"
            onClick={() => this.rotatePage(-1)}
          />
        </div>
        <SortableList
          axis="xy"
          pressDelay={200}
          items={this.getPageItems()}
          onSortEnd={this.onSortEnd}
          activePage={activePage}
          setActivePage={this.setActivePage}
        />
      </>
    );
  }

  renderLargeThumbs() {
    const items = this.getPageItems();
    return items.map((page, index) => (
      <div key={index} className="pdf-thumb">
        {page.url ? (
          <img src={page.url} alt={`page ${index}`} />
        ) : (
          <div className="loading-page">
            <div className="loader" />
          </div>
        )}
      </div>
    ));
  }

  render() {
    return this.props.onPageChange
      ? this.renderSortableSmallThumbs()
      : this.renderLargeThumbs();
  }
}

import { withAuth } from 'actions';
import { getLocal, setLocal } from 'utils/storage';

export function isSasActive(sas) {
  if (!sas) return false;
  const now = new Date();
  const expiry = new Date(sas.expiryDateUtc || null);
  return expiry - now > 30000;
}

export function getSasForURL(url, key) {
  return new Promise(resolve => {
    const sas = JSON.parse(getLocal(key));
    if (isSasActive(sas)) return resolve(sas);

    withAuth({ url }, resp => {
      setLocal(key, JSON.stringify(resp.data));
      resolve(resp.data);
    });
  });
}

export function getSas() {
  return getSasForURL('setup/sas', 'sas');
}

export function getThumbSas() {
  return getSasForURL('setup/thumb-sas', 'thumb-sas');
}

import store from 'store';
import { withAuth, dispatchWithAuth } from 'actions';

export function setClaims() {
  const url = '/account/me';
  withAuth({ url }, ({ data }) => {
    store.dispatch({ type: 'SET_CLAIMS', data });
  });
}

export function fetchMetrics() {
  const url = '/metrics';
  return dispatch => dispatchWithAuth(dispatch)({ url }, 'FETCH_METRICS');
}

export function fetchViewLogs() {
  const url = '/recent/documents';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_RECENT_DOCUMENTS');
}

export function fetchAuditLogs() {
  const url = '/recent/documentAuditLogs';
  return dispatch => dispatchWithAuth(dispatch)({ url }, 'FETCH_AUDIT_LOGS');
}

export function fetchSavedSearches() {
  const url = '/savedSearches';
  return dispatch =>
    dispatchWithAuth(dispatch)({ url }, 'FETCH_SAVED_SEARCHES');
}

export function fetchWatchList() {
  const url = '/watchList';
  return dispatch => dispatchWithAuth(dispatch)({ url }, 'FETCH_WATCHLIST');
}

export function fetchSearchResults(params, data, success) {
  const url = '/search';
  return dispatch => {
    dispatchWithAuth(dispatch, success)(
      { method: 'post', url, params, data },
      'FETCH_SEARCH_RESULTS'
    );
  };
}

export function postSavedSearch(data, success, failure) {
  const url = '/savedSearches';
  return withAuth({ method: 'post', url, data }, success, failure);
}

export function deleteSavedSearch(id, success) {
  const url = `/savedSearches/${id}`;
  return withAuth({ method: 'delete', url }, success);
}

export function changeWatch(inWatchList, keyId, success) {
  let url = '/watchList';
  if (inWatchList) {
    url = `${url}/${keyId}`;
    withAuth({ method: 'delete', url }, success);
  } else {
    const data = { keyId };
    withAuth({ method: 'post', url, data }, success);
  }
}

export function updateWatchIn(LIST, keyId, inWatchList) {
  return dispatch => {
    dispatch({
      type: `UPDATE_${LIST}_WATCH`,
      payload: { keyId, inWatchList },
    });
  };
}

export function registerSearchClick(searchId, documentId, documentPosition) {
  const url = 'search/click';
  const params = { searchId, documentId, documentPosition };
  return withAuth({ method: 'get', url, params }, () => null);
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SearchFilter = ({ onFilter }) => {
  const [value, setValue] = useState('');

  const onChange = e => {
    const text = e.target.value;
    setValue(text);
    onFilter(text);
  };

  return (
    <td className="search-filter">
      <input type="text" value={value} onChange={onChange} />
    </td>
  );
};

SearchFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export { SearchFilter };

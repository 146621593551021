import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ListAndCount } from 'utils/propTypes';
import { fetchSavedSearches, deleteSavedSearch } from 'actions/homeActions';
import { formatDateTime, shouldNavigate } from 'utils';
import IconButton from 'components/global/IconButton';


class SavedSearches extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    savedSearches: ListAndCount.isRequired,
    fetchSavedSearches: PropTypes.func.isRequired,
  }

  componentDidMount () {
    this.props.fetchSavedSearches();
  }

  handleDelete = (id) => {
    deleteSavedSearch(id, () => {
      this.props.fetchSavedSearches();
    })
  }

  onRowClick (search) {
    return (e) => {
      if (shouldNavigate(e.target)) {
        this.props.history.push(`/search/${search.searchQuery}`);
      }
    }
  }

  renderRowButtons (search) {
    const url = '/search/' + encodeURIComponent(search.searchQuery);
    return (
      <td>
        <IconButton icon="Search" isLink={true} to={url} />
        <IconButton icon="Delete" onClick={() => this.handleDelete(search.id)} />
      </td>
    )
  }

  renderTable () {
    const { entries } = this.props.savedSearches;
    return(
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>Query</th>
              <th>Last Use of Query</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {entries.map(search =>
              <tr key={search.id} onClick={this.onRowClick(search)}>
                <td>{search.searchQuery}</td>
                <td>{formatDateTime(search.createdDate)}</td>
                {this.renderRowButtons(search)}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render () {
    return (
      <div className="row">
        <div className="col s12">
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    savedSearches: state.homeStore.savedSearches,
  }
}

export default connect(mapStateToProps, {
  fetchSavedSearches,
})(SavedSearches);

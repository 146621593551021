import React from 'react';

import IconButton from './IconButton';

const Hamburger = (props) => (
  <IconButton
    icon="Folder-Menu"
    title="Show folders"
    className="hamburger"
    {...props}
  />
)

export default Hamburger;

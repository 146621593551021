import React from 'react';
import PropTypes from 'prop-types';


class SearchForm extends React.Component {
  static propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialValue: '',
    placeholder: 'Search for documents',
  }

  constructor (props) {
    super(props);

    this.state = {
      query: props.initialValue,
    }
  }

  handleChange = (e) => {
    this.setState({ query: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const query = this.state.query.trim();
    if (query.length === 0) return;
    this.props.onSubmit(query);
  }

  render () {
    return (
      <form
        className="search-form"
        onSubmit={this.handleSubmit}
      >
        <input
          type="text"
          name="query"
          placeholder={this.props.placeholder}
          value={this.state.query}
          onChange={this.handleChange}
        />
        <button
          title="Search"
          className="btn-search"
          onClick={() => false}
        >
        </button>
      </form>
    )
  }
}

export default SearchForm;

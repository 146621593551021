import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ListAndCount } from 'utils/propTypes';
import { fetchViewLogs } from 'actions/homeActions';
import { formatDateTime } from 'utils';


class Recent extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    recentDocuments: ListAndCount.isRequired,
    fetchViewLogs: PropTypes.func.isRequired,
  }

  componentDidMount () {
    this.props.fetchViewLogs();
  }

  goToDoc (doc) {
    const { id, keyId, rootFolder } = doc;
    let url = `/documents/${keyId}/${rootFolder.slug}/view/${id}`;
    return this.props.history.push(url);
  }

  renderDocuments () {
    const { entries } = this.props.recentDocuments;
    return (
      <div className="table-wrap">
        <table className="recent-documents">
          <thead>
            <tr>
              <th>APP ID</th>
              <th>Library</th>
              <th>Document Name</th>
              <th>Last Viewed</th>
            </tr>
          </thead>
          <tbody>
            {entries.map(({ entity: doc, lastViewedDate })  =>
              <tr key={doc.id} onClick={() => this.goToDoc(doc)}>
                <td>{doc.keyId}</td>
                <td>{doc.rootFolder.title}</td>
                <td>{doc.documentName}</td>
                <td>{formatDateTime(lastViewedDate)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render () {
    return (
      <div className="row recents">
        <div className="col s12">
          {this.renderDocuments()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    recentDocuments: state.homeStore.recentDocuments,
  }
}

export default connect(mapStateToProps, {
  fetchViewLogs,
})(Recent);

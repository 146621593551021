import React from 'react';
import AppIdWatch from './AppIdWatch';
import { Link } from 'react-router-dom';

import logo from 'images/Logo-BIB.png';

import UserProfile from 'components/global/UserProfile';
import SearchForm from 'components/global/SearchForm';
import AppInfoHeader from './AppInfoHeader';
import { getLocal } from 'utils/storage';

const AppDetailHeader = (props) => {
    return (
        <div className="row">
            <div className="col s11 m-4-half l3">
                <h2>
                    <Link to="/">
                        <img src={logo} alt="Build It Back" />
                        <span>Home</span>
                    </Link>
                </h2>
                <div className="app-id">
                    <div>{props.keyId}</div>
                    <BackArrow/>
                </div>
                <AppIdWatch keyId={props.keyId} />
                <SearchForm
                    placeholder="Search within this folder"
                    initialValue={props.query}
                    onSubmit={props.onSubmit}
                /> 
            </div>
            <AppInfoHeader keyId={props.keyId} />
            <div className='col  l-half-1  text-right'>
                <UserProfile />
            </div>

        </div>
    )
}



const BackArrow =()=>{
    let navigatedFromSearch =()=> {
        const lastPath = getLocal('lastSearchPath');
        return lastPath && lastPath.indexOf('/search/') === 0;
    }
    

    const lastPath = getLocal('lastSearchPath');
    
    return navigatedFromSearch() ? (
      <Link to={lastPath}>← Results</Link>
    ) : null;
}


export default AppDetailHeader
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListAndCount } from 'utils/propTypes';
import { fetchAuditLogs } from 'actions/homeActions';
import { formatDateTime } from 'utils';
import _ from 'utils/fp';

const CHANGE_MAP = {
  DocumentName: 'Document Name',
  HroClassified: 'HRO Classified',
  RefId: 'Ref ID',
  Folder: 'Folder',
};

const CHANGE_TYPES = {
  insert: 'UPLOAD',
  update: 'EDIT',
  remove: 'DELETE',
  delete: 'DELETE',
  print: 'PRINT',
  merge: 'MERGE',
  split: 'SPLIT',
  modify: 'MODIFY',
  modifyDocumentBlob:'MODIFY',
  download: 'DOWNLOAD'
};

const formatValue = value => {
  if (value === true) return 'Yes';
  else if (value === false) return 'No';
  else if (!value) return 'None';
  else return value;
};

let TableEntry =(props)=>{
  const {doc,changeType} = props;
    return (
      <tr  onClick={props.onClick}>
        <td nowrap="true">{doc.keyId}</td>
        <td>{doc.rootFolder.title}</td>
        <td>{doc.documentName}</td>
        <td>{_.get(CHANGE_TYPES, changeType, changeType)}</td>
        <td>{formatDateTime(props.log.changeDate)}</td>
        <td>{props.children}</td>
      </tr>
  )
}
class AuditLog extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    auditLogs: ListAndCount.isRequired,
    fetchAuditLogs: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchAuditLogs();
  }

  goToDoc(doc) {
    const { id, keyId, rootFolder } = doc;
    let url = `/documents/${keyId}/${rootFolder.slug}/view/${id}`;
    return this.props.history.push(url);
  }

  renderChanges(changes, changeType) {
    const showOld = changeType !== 'insert';
    return changes.map(change => (
      <p key={change.fieldName}>
        {CHANGE_MAP[change.fieldName] + ': '}
        {showOld ? formatValue(change.oldValue) + ' => ' : null}
        {formatValue(change.newValue) || 'None'}
      </p>
    ));
  }
  renderSourceDocs(sourceDocuments){
    return sourceDocuments.map((sourceDoc)=><Link key={sourceDoc.documentName}to={this.generateLinks(sourceDoc)}  onClick={e => e.stopPropagation()} target="_blank">{this.shortenFileName(sourceDoc.documentName)}</Link>);
  }
  generateLinks(sourceDocument){
    const { id, keyId, rootFolder } = sourceDocument;
    return `/documents/${keyId}/${rootFolder.slug}/view/${id}`;
  }
  shortenFileName(documentName){
    let split = documentName.split('.');
    let fileName = split[0];
    let ext = split[1];
    const lengthStr = fileName.length;
    if(fileName.length > 15){
      return `${fileName.substring(0,10)}...${fileName.substring(lengthStr-6,lengthStr-1)}.${ext}`;
    }
    return documentName;
  }

  renderLog({ document: doc, changes, changeType, ...log }) {
    const key = `${doc.id}-${log.changeDate}`;
    switch(changeType){
      case 'print':
        return (
          <TableEntry key={key} doc={doc} log={log}
          changeType={changeType}
          onClick={() => this.goToDoc(doc)}
          >
            Print Request Initiated
          </TableEntry>
  
        );
      case 'modify':
      case 'modifyDocumentBlob':
      case 'download':
        return (
          <TableEntry key={key} doc={doc} log={log}
          changeType={changeType}
          onClick={() => this.goToDoc(doc)}
          >
          </TableEntry>
        )
      case 'merge':
        return (
          <TableEntry key={key} doc={doc} log={log}
          changeType={changeType}
          onClick={() => this.goToDoc(doc)}
          >
           
            Source Documents
            <br/>
         
            {this.renderSourceDocs(doc.mergeSourceDocuments)}
            
          </TableEntry>
        )
      case 'split': 
      return (
        <TableEntry key={key} doc={doc} log={log}
        changeType={changeType}
        onClick={() => this.goToDoc(doc)}
        >
          Split Document
          <br/>
          <Link to={this.generateLinks(doc)}  target="_blank" onClick={e => e.stopPropagation()}>{doc.documentName}</Link>
        </TableEntry>
      )
      default:
        if(!changes)return null;

        const filteredChanges = changes.filter(change =>
          CHANGE_MAP.hasOwnProperty(change.fieldName)
        );
        if (filteredChanges.length === 0) return null;
        return (
          <TableEntry key={key} doc={doc} log={log}
          changeType={changeType}
          onClick={() => this.goToDoc(doc)}
          >
            {this.renderChanges(filteredChanges, changeType)}
          </TableEntry>
        )

    }
  }

  renderLogs() {
    const { entries } = this.props.auditLogs;
    return (
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>APP ID</th>
              <th>Library</th>
              <th>Document</th>
              <th>Action</th>
              <th>Time</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>{entries.map(entry => this.renderLog(entry))}</tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="row audit-log">
        <div className="col s12">{this.renderLogs()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auditLogs: state.homeStore.auditLogs,
  };
}

export default connect(mapStateToProps, {
  fetchAuditLogs,
})(AuditLog);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/global/IconButton';

function SplitForm({ splitDocument, onCancel, pageCount }) {
  const [splitStart, setSplitStart] = useState('');
  const [splitEnd, setSplitEnd] = useState('');
  const [error, setError] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    const start = parseInt(splitStart, 10);
    const end = parseInt(splitEnd, 10);
    if (isNaN(start) || isNaN(end) || start < 1 || end > pageCount) {
      setError(`Please enter valid numbers between 1 and ${pageCount}`);
    } else if (end < start) {
      setError('"From" should be less than "To"');
    } else {
      setError('');
      splitDocument(start, end);
    }
  };

  return (
    <div className="split-form">
      <form onSubmit={onSubmit}>
        <span>Split range</span>{' '}
        <input
          type="text"
          value={splitStart}
          placeholder="From"
          onChange={e => setSplitStart(e.target.value)}
        />
        <input
          type="text"
          value={splitEnd}
          placeholder="To"
          onChange={e => setSplitEnd(e.target.value)}
        />
        <button className="btn-primary">Split</button>
        <IconButton icon="Close" className="btn-action" onClick={onCancel} />
      </form>
      {error && <div className="float-error">{error}</div>}
    </div>
  );
}

SplitForm.propTypes = {
  splitDocument: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default SplitForm;

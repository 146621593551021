import _ from 'utils/fp';
import { getViewedDocuments, setViewedDocuments } from 'utils/viewed';

const defaultState = {
  documents: {
    count: 0,
    entries: [],
    metadata: null,
  },
  documentResults: {
    count: 0,
    entries: [],
    metadata: {},
  },
  document: {},
  actionedUsers: [],
  lastSearchQuery: '',

  privileges: {},
  classifiedCategories: [],
  relatedCategories: [],
  listTitles: {},

  sas: {
    folderUri: '',
  },

  applicationInfo:{},

  refIdCounts: {},
  categoryCounts: {},
  documentTypes: [],
  documentCategories: [],
  documentSubCategories: [],
  documentPages: [],
  rootFolders: [],
  uraTypes: [],
  inWatchList: false,
  categoriesLoading: true,
  viewedDocuments: getViewedDocuments(),
};

function digestDocuments(state, payload) {
  const { count, entries, metadata } = payload.data;
  return {
    count,
    metadata,
    entries: [...state.documents.entries, ...entries],
  };
}

function findDocument(state, { documentId }) {
  const id = parseInt(documentId, 10);
  if (state.document.id === id) return state.document;

  let result = { ...defaultState.document };
  for (const doc of state.documents.entries) {
    if (doc.id === id) result = { ...doc };
  }
  return result;
}

function sorted(items) {
  return _.sortBy(items, item => item.type.title);
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES': {
      const classifiedCategories = [];
      const relatedCategories = [];
      const categoryCounts = {};
      const refIdCounts = {};
      const listTitles = {};
      const privileges = { ...state.privileges };

      action.payload.data.forEach(({ count, type }) => {
        let categoryPrivilges = {};
        const { title, slug, refIdDisplayTitle } = type;

        if (type.sectionName === 'APPLICATION DOCUMENTS') {
          let sortedCategories = _.sortBy(type.documentCategories, [(e) => { return e.orderIndex; }]);

          sortedCategories.forEach((category, index) => {
            privileges['apl-' + category.type.slug] = {
              title,
              refIdDisplayTitle,
              canInitiateUpload: true,
              ...category.type.privileges,
            };
            listTitles[category.type.slug] = category.type.title;
            classifiedCategories.push({ ...category, rootFolder: type.slug });

            if (index === 0) {
              privileges['apl-auto'] = {
                title,
                refIdDisplayTitle,
                canInitiateUpload: true,
                ...category.type.privileges,
              };
              listTitles['auto'] = category.type.title;
              classifiedCategories.push({ ...category, rootFolder: 'auto' });
            }
          });

          categoryPrivilges = type.documentCategories[0].type.privileges;
        } else {
          relatedCategories.push({ count, type });
          categoryPrivilges = {
            ...type.privileges,
            canInitiateUpload: count > 0,
          };
        }

        listTitles[slug] = title;
        privileges[slug] = { title, refIdDisplayTitle, ...categoryPrivilges };
        categoryCounts[slug] = type.documentCategories;
        refIdCounts[slug] = type.refIds.filter(item => item.type);
      });

      return {
        ...state,
        classifiedCategories: sorted(classifiedCategories),
        relatedCategories: sorted(relatedCategories),
        categoriesLoading: false,
        categoryCounts,
        refIdCounts,
        listTitles,
        privileges,
      };
    }

    case 'RESET_CATEGORIES': {
      return {
        ...state,
        categoriesLoading: true,
      };
    }

    case 'FETCH_DOCUMENTS': {
      const { actionedUsers } = action.payload.data.metadata;
      return {
        ...state,
        documents: digestDocuments(state, action.payload),
        actionedUsers,
      };
    }

    case 'RESET_DOCUMENTS': {
      return {
        ...state,
        documents: { ...defaultState.documents },
      };
    }

    case 'FETCH_DOCUMENT_RESULTS': {
      return {
        ...state,
        lastSearchQuery: action.params.query,
        documentResults: action.payload.data.count > 0 ? action.payload.data : state.documentResults
      };
    }

    case 'RESET_DOCUMENT_RESULTS': {
      return {
        ...state,
        documentResults: { ...defaultState.documentResults },
      };
    }

    case 'FETCH_DOCUMENT': {
      return {
        ...state,
        document: { ...action.payload.data },
      };
    }

    case 'RESET_DOCUMENT': {
      return {
        ...state,
        document: findDocument(state, action.payload),
      };
    }

    case 'FETCH_SAS': {
      return {
        ...state,
        sas: { ...action.payload.data },
      };
    }

    case 'FETCH_APPID_WATCH': {
      return {
        ...state,
        inWatchList: action.payload.data.inWatchlist,
      };
    }

    case 'UPDATE_APPID_WATCH': {
      return {
        ...state,
        inWatchList: action.payload.inWatchList,
      };
    }

    case 'FETCH_SETUP_DOCUMENT_TYPES': {
      return {
        ...state,
        documentTypes: [...action.payload.data.entries],
      };
    }

    case 'FETCH_SETUP_CATEGORIES': {
      return {
        ...state,
        documentCategories: [...action.payload.data.entries],
      };
    }

    case 'FETCH_SETUP_SUBCATEGORIES': {
      return {
        ...state,
        documentSubCategories: [...action.payload.data.entries],
      };
    }

    case 'FETCH_SETUP_FOLDERS': {
      return {
        ...state,
        rootFolders: [...action.payload.data.entries],
      };
    }

    case 'FETCH_SETUP_URA_TYPES': {
      return {
        ...state,
        uraTypes: [...action.payload.data.entries],
      };
    }

    case 'FETCH_PAGES': {
      return {
        ...state,
        documentPages: [...action.payload.data.entries],
      };
    }

    case 'ADD_TO_VIEWED_DOCUMENTS': {
      let { viewedDocuments } = state;
      const id = action.data;
      if (viewedDocuments.has(id)) {
        return state;
      } else {
        viewedDocuments = setViewedDocuments([...viewedDocuments, id]);
        return { ...state, viewedDocuments };
      }
    }

    case 'FETCH_APPLICATION_INFO':{
      return {
        ...state,
        applicationInfo: action.payload.data
      };

    }

    default:
      return state;
  }
};

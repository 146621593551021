import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';

const PAGE_SIZE = 40;
const MAX_COUNT = 100000;
const MAX_PAGES = MAX_COUNT / PAGE_SIZE;
const TOOLTIP_MSG =
  'Please search with precise keywords to get relevant results';

const Showing = ({ count, currentPage, loaded }) => {
  if (!loaded) return null;

  const start = 1 + currentPage * PAGE_SIZE;
  const end = Math.min((currentPage + 1) * PAGE_SIZE, count);

  let tooltip = null;
  if (count > MAX_COUNT) {
    tooltip = (
      <div className="inline-tooltip">
        <span data-tip={TOOLTIP_MSG}>i</span>
        <ReactTooltip place="top" effect="solid" />
      </div>
    );
  }

  return (
    <div>
      Showing{' '}
      <strong>
        {start} - {end}
      </strong>
      {' of '}
      <strong> {count}</strong> results
      {tooltip}
    </div>
  );
};

Showing.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const Pagination = ({ count, loaded, position, currentPage, fetch }) => {
  if (count === 0) return null;

  const showingProps = { count, loaded, currentPage };
  const showing = <Showing {...showingProps} />;
  const containerClass = 'pagination-container position-' + position;
  const pageCount = Math.min(count / PAGE_SIZE, MAX_PAGES);

  return (
    <div className={containerClass}>
      {position === 'top' && showing}
      {count > PAGE_SIZE ? (
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          breakLabel="..."
          pageCount={pageCount}
          forcePage={currentPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={data => fetch(data.selected)}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      ) : null}
      {position === 'bottom' && showing}
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
};

export { Pagination, PAGE_SIZE };

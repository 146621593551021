import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ show, children,onClose }) => {
    if(show){
      return (
        <>
        <div className='modal-overlay'/>

        <div className="flex-container">
          <div className="flex-modal flex-alert-modal">
          <div className='flex-modal-content'>

              {children}

            <section>
              <button onClick={onClose}>OK</button>
            </section>
          </div>

          </div>
          </div>

        </>
    )
    }
    return null;

}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired
}

export default Modal;

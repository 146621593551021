import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Login, Logout, LogoutSuccess } from 'actions/auth';
import ScrollToTop from 'components/global/ScrollToTop';
import NotFound from 'components/global/NotFound';

import Home from 'components/home/Home';
import Search from 'components/search/Search';
import AppDetail from 'components/detail/AppDetail';

import 'scss/styles.scss';

class App extends React.Component {
  render() {
    return (
      <ScrollToTop>
        <Switch>
          <Route exact path="/logout" render={Logout} />
          <Route exact path="/redirect" render={Login} />
          <Route exact path="/pages/logout" component={LogoutSuccess} />

          <Route exact path="/search/:query" component={Search} />

          <Route
            exact
            path="/documents/:keyId/search/:query"
            component={props => <AppDetail {...props} />}
          />
          <Route path="/documents/:keyId" component={AppDetail} />
          <Route path="/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    );
  }
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import { shouldNavigate } from 'utils';
import { registerSearchClick } from 'actions/homeActions';
import { getSas } from 'actions/sas';
import { searchDocuments } from 'actions/appsActions';
import { ListCountMetadata } from 'utils/propTypes';
import { getResultPrivileges } from 'utils/privileges';
import { getLocal, setLocal } from 'utils/storage';

import _ from 'utils/fp';

import IconButton from 'components/global/IconButton';
import Hamburger from 'components/global/Hamburger';
import ColumnSelector from 'components/global/ColumnSelector';
import { SearchFilter } from 'components/global/SearchFilter';
import { Pagination, PAGE_SIZE } from 'components/global/Pagination';

import Modal from 'components/global/Modal';
import PDFDEFAULT from '../../flat-document.svg';

const HIDE_COLS_KEY = 'documentResultsHiddenCols';

class DocumentResults extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    keyId: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,

    privileges: PropTypes.object.isRequired,
    documentResults: ListCountMetadata.isRequired,
    searchDocuments: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    toggleSideMenu: PropTypes.func.isRequired,
    viewedDocuments: PropTypes.instanceOf(Set).isRequired,
  };

  constructor(props) {
    super(props);
    const { keyId } = this.props;
    const hiddenCols = JSON.parse(getLocal(HIDE_COLS_KEY)) || [];
    this.state = {
      isLoading: false,
      isMerging: false,
      mergeIds: [],
      currentView: 'list',
      currentPage: 0,
      showColumnNames: false,
      hiddenCols,
      sortProp: null,
      sortOrder: 'desc',
      filters: { keyId },
      showModal: false,
      mergeMetaData:{},
    };
  }

  getColumnNames() {
    const { currentView } = this.state;
    const showThumbs = currentView === 'thumbs';
    return [
      showThumbs ? 'Thumbnail' : '',
      'Document Name',
      'Library',
      'Category',
      'Document Type',
      'Ref ID',
      'Modified By',
      'Created By',
      'IW cfd.',
    ];
  }

  componentDidMount() {
    this.fetchDocuments();
    this.changeCurrentView(this.state.currentView);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.fetchDocuments();
    }
  }

  getParams(page) {
    const params = { skip: page * PAGE_SIZE };
    const { sortProp, sortOrder } = this.state;
    if (sortProp) {
      params.orderBy = sortProp;
      params.orderByDirection = sortOrder;
    }
    return params;
  }

  fetchDocuments(page = 0) {
    const params = this.getParams(page);
    const filters = {
      query: this.props.query,
      ...this.state.filters,
    };

    this.props.toggleSideMenu(false);
    this.setState({ isLoading: true });
    this.props.searchDocuments(params, filters, (response) => {
      this.setState({ isLoading: false, currentPage: page });
    
      if(response.data.count < 1){
        this.setState({showModal:true})
      } 
    });
  }

  changeCurrentView = viewType => {
    getSas().then(() => {
      this.setState({
        currentView: viewType,
      });
    });
  };

  hideColumns = hiddenCols => {
    setLocal(HIDE_COLS_KEY, JSON.stringify(hiddenCols));
    this.setState({ hiddenCols, showColumnNames: false });
  };

  hideModalFunc = () =>{this.setState({showModal:false})}

  getDetailUrl({ id, rootFolderSlug }) {
    const { keyId } = this.props;
    return `/documents/${keyId}/${rootFolderSlug}/view/${id}`;
  }

  goToDetail(result, pos) {
    const { metadata } = this.props.documentResults;
    registerSearchClick(metadata.searchId, result.id, pos);
    this.props.history.push(this.getDetailUrl(result));
  }

  onRowClick(result, pos) {
    return e => {
      if (shouldNavigate(e.target)) {
        this.goToDetail(result, pos);
      }
    };
  }

  setSorter = property => {
    return () => {
      // Toggle sortOrder if sorting on the same property
      // Otherwise, just use ascending as the default
      const { sortProp, sortOrder } = this.state;
      const toggled = sortOrder === 'asc' ? 'desc' : 'asc';
      const newOrder = sortProp === property ? toggled : 'asc';
      this.setState({ sortProp: property, sortOrder: newOrder }, () =>
        this.fetchDocuments()
      );
    };
  };

  getSortHead(property, label, xl) {
    // Show asc desc sort icon only for current sortProp
    const { sortProp, sortOrder } = this.state;
    const sortClass = sortProp === property ? sortOrder : '';
    return (
      <th className={xl ? 'xl ' : ''} onClick={this.setSorter(property)}>
        <span className={sortClass}>{label}</span>
      </th>
    );
  }

  handleMergeChange(docId) {
    const { mergeIds } = this.state;
    let newIds = _.toggleArrayItem(mergeIds, docId);
    let result = this.state.mergeMetaData;

    result[docId] = _.findBy('id', this.props.documentResults.entries, docId)
    
    let keysinMetaData = Object.keys(result);
    let keytoberemoved = keysinMetaData.filter(key => !newIds.includes(key));
    
    result = _.omit(result,keytoberemoved);
    
    this.setState({ mergeIds:  newIds,mergeMetaData: result});
 
  }

  renderMergeColumns(docId) {
    const mergeIndex = this.state.mergeIds.indexOf(docId);
    return (
      <>
        <td className="merge-col" onClick={e => e.stopPropagation()}>
          <input
            type="checkbox"
            value={mergeIndex !== -1}
            checked={mergeIndex !== -1}
            onClick={() => this.handleMergeChange(docId)}
          />
        </td>
        <td className="merge-col">
          <span>{mergeIndex > -1 ? mergeIndex + 1 : ' '}</span>
        </td>
      </>
    );
  }

  renderRowButtons(result, pos) {
    const { privileges, onDownload, viewedDocuments } = this.props;
    const { canDownload, canEditBasicMetadata } = getResultPrivileges(
      privileges,
      result
    );
    const editUrl = this.getDetailUrl(result).replace('/view/', '/edit/');

    return (
      <td>
        <IconButton
          icon="View"
          active={viewedDocuments.has(result.id)}
          onClick={() => this.goToDetail(result, pos)}
        />
        {canEditBasicMetadata ? (
          <IconButton icon="Edit" isLink={true} to={editUrl} />
        ) : null}
        {canDownload ? (
          <IconButton icon="Download" onClick={() => onDownload(result)} />
        ) : null}
      </td>
    );
  }

  renderPagination(position) {
    const { count, entries } = this.props.documentResults;
    const { currentPage } = this.state;
    const paginationProps = {
      count,
      position,
      currentPage,
      loaded: entries.length > 0,
      fetch: page => this.fetchDocuments(page),
    };
    return <Pagination {...paginationProps} />;
  }

  getThumb(doc) {
    const src = doc.thumbBlobUrl;
    return <img src={!!src?src:PDFDEFAULT} alt={doc.documentName} onError={(e)=>{e.target.src = PDFDEFAULT}} />;
  }

  mergeDocuments = () => {
    const { history } = this.props;
    const { mergeIds } = this.state;
    console.log(this.state)

    const { id, keyId, rootFolderSlug } = this.state.mergeMetaData[mergeIds[0]];

    const url = `/documents/${keyId}/${rootFolderSlug}/edit/${id}`;
    const queryString = qs.stringify({
      merge: 1,
      mergeIds: mergeIds.join('-'),
    });
   
    history.push(url + '?' + queryString);
  };

  renderActions() {
    const {
      currentView,
      showColumnNames,
      hiddenCols,
      isMerging,
      mergeIds,
    } = this.state;
    if (isMerging) {
      return (
        <div className="actions">
          <button
            className="btn-primary btn-merge"
            disabled={mergeIds.length < 2}
            onClick={() => this.mergeDocuments()}
          >
            Merge
          </button>
          <IconButton
            icon="Close"
            className="btn-action"
            onClick={() => this.setState({ isMerging: false })}
          />
        </div>
      );
    }

    return (
      <div className="actions">
        <IconButton
          icon="Merge"
          title="Merge Documents"
          className="btn-icon"
          onClick={() => this.setState({ isMerging: !isMerging })}
        />
        {showColumnNames && (
          <ColumnSelector
            columns={this.getColumnNames()}
            hiddenCols={hiddenCols}
            onDone={cols => this.hideColumns(cols)}
            onCancel={() => this.setState({ showColumnNames: false })}
          />
        )}
        <button
          title="Add/Remove Columns"
          className={'show-cols' + (showColumnNames ? ' active' : '')}
          onClick={() => this.setState({ showColumnNames: true })}
        >
          ▥
        </button>
        <IconButton
          icon="List"
          title="Show list"
          className={
            'btn-icon show-list' + (currentView === 'list' ? ' active' : '')
          }
          onClick={() => this.changeCurrentView('list')}
        />
        <IconButton
          icon="Thumbnails"
          title="Show thumbnails"
          className={
            'btn-icon show-thumbs' + (currentView === 'thumbs' ? ' active' : '')
          }
          onClick={() => this.changeCurrentView('thumbs')}
        />
      </div>
    );
  }

  onFilter(field) {
    return text => {
      const { filters } = this.state;
      if (text.length === 0) delete filters[field];
      else filters[field] = text;
      this.setState({ filters });
    };
  }

  submitFilters = () => {
    this.fetchDocuments();
  };

  renderTable() {
    const { entries } = this.props.documentResults;
    const { isLoading, isMerging, currentView, hiddenCols } = this.state;
    const showThumbs = currentView === 'thumbs';


    const resultsClassName = [
      'results-wrap',
      ...hiddenCols.map(c => `hide-col-${c + 1}`),
      showThumbs ? '' : 'hide-col-1',
    ].join(' ');

    return (
      <div className={resultsClassName} onClick={this.hideModalFunc}>
        {isLoading ? <span className="loader" /> : null}

        <Modal show={this.state.showModal} onClose={this.hideModalFunc}>
          There are no matching results for the given search condition(s).
          <section> Please try again.</section>
        </Modal>

        <div className="table-wrap sortable">
          <table className={showThumbs ? 'has-thumbs' : null}>
            <thead>
              <tr>
                <th>{showThumbs ? 'Thumbnail' : null}</th>
                {isMerging && (
                  <>
                    <th>Merge</th>
                    <th></th>
                  </>
                )}
                {this.getSortHead('DocumentName', 'Document Name')}
                {this.getSortHead('RootFolderTitle', 'Library')}
                {this.getSortHead('Category', 'Category')}
                {this.getSortHead('DocumentType', 'Document Type')}
                {this.getSortHead('RefId', 'Ref ID')}
                {this.getSortHead('ModifiedBy', 'Modified By')}
                {this.getSortHead('CreatedBy', 'Created By')}
                <th>IW cfd.</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr onKeyPress={e => e.key === 'Enter' && this.submitFilters()}>
                <td className="search-filter"></td>
                {isMerging && (
                  <>
                    <td className="search-filter"></td>
                    <td className="search-filter"></td>
                  </>
                )}
                <SearchFilter onFilter={this.onFilter('documentName')} />
                <SearchFilter onFilter={this.onFilter('rootFolderTitle')} />
                <SearchFilter onFilter={this.onFilter('category')} />
                <SearchFilter onFilter={this.onFilter('documentType')} />
                <SearchFilter onFilter={this.onFilter('refId')} />
                <SearchFilter onFilter={this.onFilter('modifiedBy')} />
                <SearchFilter onFilter={this.onFilter('createdBy')} />
                <td className="search-filter"></td>
                <td className="search-filter text-center">
                  <button onClick={this.submitFilters}>Search</button>
                </td>
              </tr>
              {entries.map((result, pos) => (
                <tr key={result.id} onClick={this.onRowClick(result, pos)}>
                  <td>{showThumbs ? this.getThumb(result) : null}</td>
                  {isMerging && this.renderMergeColumns(result.id)}
                  <td className="bw">{result.documentName}</td>
                  <td>{result.rootFolderTitle}</td>
                  <td>{result.category}</td>
                  <td>{result.documentType}</td>
                  <td>{result.refId}</td>
                  <td>{result.modifiedBy}</td>
                  <td>{result.createdBy}</td>
                  <td>{result.iwClassified ? 'Yes' : 'No'}</td>
                  {this.renderRowButtons(result, pos)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderHeading() {
    return (
      <div className="main-header">
        <Hamburger onClick={() => this.props.toggleSideMenu(true)} />
        <h4>{`Search results for "${this.props.query}"`}</h4>
        {this.renderActions()}
      </div>
    );
  }

  render() {
    return (
      <div className="main document-results">
        <div className="container-fluid">
          <div className="row">
            <div className="col s12">
              <a href="/#" download ref={link => (this.downloadLink = link)}>
                Download
              </a>
              {this.renderHeading()}
              {this.renderPagination('top')}
              {this.renderTable()}
              {this.renderPagination('bottom')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return _.pick(state.appStore, [
    'documentResults',
    'privileges',
    'viewedDocuments',
  ]);
}

export default connect(
  mapStateToProps,
  {
    searchDocuments,
  }
)(DocumentResults);

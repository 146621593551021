import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


class UserProfile extends React.Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }

  render () {
    const { firstName, lastName,privileges } = this.props;
    console.log(this.props)
    const initials = firstName.slice(0, 1) + lastName.slice(0, 1);
    const isAdmin = !!privileges && privileges.hasAdminPermissions ;
    return (
      <div className="profile">
        <span className="avatar">{initials}</span>
        <div className="popover">
          <span>{firstName + ' ' + lastName}</span>
          {
            isAdmin?
            <a href={privileges.adminUrl}>Admin Portal</a>
            :
            null
          }
          <Link to='/logout'>Logout</Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    firstName: state.homeStore.claims.firstName,
    lastName: state.homeStore.claims.lastName,
    privileges: state.homeStore.claims.privileges
  }
}

export default connect(mapStateToProps)(UserProfile);

import moment from 'moment-timezone';

export const NBSP = '\u00A0';
export const DOCUMENT_TITLE = 'HRO DMS';

function getMoment (string) {
  return moment(string).tz('America/New_York');
}

export function formatDate (string) {
  if (!string) return NBSP;
  return getMoment(string).format('MM/DD/YYYY');
}

export function formatDateTime (string) {
  if (!string) return NBSP;
  return getMoment(string).format('MM/DD/YYYY h:mm A');
}

export function getTodayForName () {
  const today = new Date().toISOString();
  return formatDate(today).replace(/\//g, '');
}

export function getFileExtension (filename) {
  const parts = filename.trim().split('.')
  if (parts.length < 2) return null;
  return parts[parts.length - 1].toUpperCase();
}

export function shortenName (name) {
  if (name.length < 30) return name;
  return name.slice(0, 15) + '...' + name.slice(name.length - 12)
}

export function normalizeName (name) {
  return name.replace(/[^a-zA-Z0-9_-]/g, '_');
}

// Used to determine when a click on table row should navigate
// the user to the link related to that row
export function shouldNavigate (target) {
  return (
    target.nodeName === 'IMG' ||
    (target.nodeName === 'TD' && target.nextSibling)
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const IconButton = ({ icon, isLink, active, ...rest }) => {
  const props = !!rest.altname ? { title: rest.altname, ...rest }:{ title: icon, ...rest }
 
  if (active) props.className += ' active';

  const Element = isLink ? Link : 'button';

  return (
    <Element {...props}>
      <span className={'svg-Icon-' + icon} />
    </Element>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
};

export default IconButton;

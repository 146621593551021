import PropTypes from 'prop-types';

export const ListAndCount = PropTypes.shape({
  count: PropTypes.number.isRequired,
  entries: PropTypes.array.isRequired,
})

export const ListCountMetadata = PropTypes.shape({
  count: PropTypes.number.isRequired,
  entries: PropTypes.array.isRequired,
  metadata: PropTypes.object,
})

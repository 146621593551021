import React from 'react';
import PropTypes from 'prop-types';

import { postSavedSearch } from 'actions/homeActions';


class SearchForm extends React.Component {
  static propTypes = {
    searchQuery: PropTypes.string.isRequired,
  }

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  handleSave = () => {
    const { searchQuery } = this.props;
    this.setState({ isLoading: true });
    postSavedSearch(
      { searchQuery },
      () => this.setState({ isLoading: false }),
      () => this.setState({ isLoading: false })
    );
  }

  render () {
    const { isLoading } = this.state;

    return (
      <button
        className="btn-link"
        disabled={isLoading}
        onClick={this.handleSave}
      >
        Save Search
        { isLoading ? <span className="loader"/> : null}
      </button>
    )
  }
}

export default SearchForm;

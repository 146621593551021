import React from 'react';
import PropTypes from 'prop-types';

import _ from 'utils/fp';
import {
  isDocURA,
  isDocPDR,
  getOriginalName,
  mapTitles,
  folderNeedsSubCategory,
} from 'utils/documents';
import ERRORS from './errors';
import UserDateData from './UserDateData';
import { Input, TSelect } from '../global/Input';

export default class Form extends React.Component {
  static propTypes = {
    doc: PropTypes.object,
    privileges: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    rootFolders: PropTypes.array.isRequired,
    documentCategories: PropTypes.array.isRequired,
    documentSubCategories: PropTypes.array.isRequired,
    documentTypes: PropTypes.array.isRequired,
    uraTypes: PropTypes.array.isRequired,
    refIdCounts: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    const { doc } = props;
    let banWriteToManualClassification = doc.documentCategory.slug === 'manual-classification';

    
    this.state = {
      documentName: doc.documentName,
      rootFolderId: doc.rootFolderId,
      documentCategoryId: banWriteToManualClassification? null: doc.documentCategoryId,
      documentSubCategoryId: doc.documentSubCategoryId,
      documentTypeId: doc.documentTypeId,
      hroClassified: doc.hroClassified,
      tenantId: doc.tenantId || '',
      displacementTypeId: doc.displacementTypeId,
      documentFolderId: doc.documentFolderId,
      documentSubFolderId: doc.documentSubFolderId,
      refId: doc.refId,
      applicantName: doc.applicantName,
      documentDescription: doc.documentDescription,
      errors: {},
      slug: doc.rootFolder.slug,
      isDocUra: isDocURA(doc),
    };
    this.requiredFields = ['documentName','documentCategoryId','documentTypeId'];
    if (this.state.isDocUra) {
      this.requiredFields.push('tenantId');
    }

    if (folderNeedsSubCategory(this.state.slug)) {
      this.requiredFields.push('documentSubCategoryId');
    }    
   console.log(this.props)
  }
  getDocumentFolderOptions() {
    const { displacementTypeId } = this.state;
    const displacementType = _.findById(
      this.props.uraTypes,
      displacementTypeId
    );
    return displacementType ? mapTitles(displacementType.documentFolders) : [];
  }

  getDocumentSubFolderOptions() {
    const { displacementTypeId, documentFolderId } = this.state;
    const displacementType = _.findById(
      this.props.uraTypes,
      displacementTypeId
    );
    if (!displacementType) return [];
    const documentFolder = _.findById(
      displacementType.documentFolders,
      documentFolderId
    );
    return documentFolder ? mapTitles(documentFolder.documentSubFolders) : [];
  }

  getRootFolderOptions() {
    const options = this.props.rootFolders.map(library => {
      return { label: library.title, value: library.id };
    });
    return _.iSortBy(options, 'label');
  }
  getSlug(parentFolder,itemId){
    const folder = parentFolder.find(folderMetaData=>folderMetaData.id === itemId);
    return {slug:folder.slug};
  }
  handleChangeRequiredFields(){
    if(folderNeedsSubCategory(this.state.slug)){
      if(!this.requiredFields.includes('documentSubCategoryId'))this.requiredFields.push('documentSubCategoryId');
    }
    else{
      if(this.requiredFields.includes('documentSubCategoryId')) this.requiredFields = this.requiredFields.filter((required_fields)=>{return required_fields !== 'documentSubCategoryId'});
    }

    if(this.state.isDocUra ) {
     if(!this.requiredFields.includes('tenantId')) this.requiredFields.push('tenantId');
    }
    else{
      if(this.requiredFields.includes('tenantId')) this.requiredFields = this.requiredFields.filter((required_fields)=>{return required_fields !== 'tenantId'});
    }

  }
  getCategoryOptions() {
    const options = this.props.documentCategories
      .filter(category => category.rootFolderId === this.state.rootFolderId)
      .filter(item => item.showInEdit)
      .map(category => {
        return { label: category.title, value: category.id };
      });
    return _.iSortBy(options, 'label');
  }

  getSubCategoryOptions() {
    const { documentCategoryId, documentSubCategoryId } = this.state;
    const options = this.props.documentSubCategories
      .filter(sub => sub.documentCategoryId === documentCategoryId)
      .filter(item => item.showInEdit || item.id === documentSubCategoryId)
      .map(sub => ({ label: sub.title, value: sub.id }));
    return _.iSortBy(options, 'label');
  }
  getTenantIdsOptions(){
    const {tenantIds} = this.props;
    const options = tenantIds.map(tenantObj =>{
      return {label:tenantObj.tenantId,value:tenantObj.tenantId};
    })
    return _.iSortBy(options,'label');
  }

  getDocumentTypeOptions() {
    const {
      documentCategoryId,
      documentTypeId,
      documentSubCategoryId,
    } = this.state;
    const options = this.props.documentTypes
      .filter(
        type =>
          type.documentCategoryId === documentCategoryId &&
          type.documentSubCategoryId === documentSubCategoryId &&
          (type.showInEdit || type.id === documentTypeId)
      )
      .map(type => {
        return { label: type.documentTitle, value: type.id };
      });
    return _.iSortBy(options, 'label');
  }

  getRefIdOptions(rootFolderId) {
    const { rootFolders, refIdCounts } = this.props;
    const library = _.findById(rootFolders, rootFolderId);

    if (!library || library.slug === 'application') {
      return [];
    } else {
      const refIds = _.get(refIdCounts, library.slug, []) || [];
      return refIds.map(({ type }) => ({ label: type, value: type }));
    }
  }

  getClassifiedOptions() {
    return [{ label: 'Yes', value: true }, { label: 'No', value: false }];
  }

  validate() {
    let errors = {};
    for (const field of this.requiredFields) {
      if (!this.state[field]) {
        errors[field] = ERRORS.required;
      }
    }

    const categoryOptions = this.getCategoryOptions();
    const { documentCategoryId } = this.state;
    if ((categoryOptions.length && !documentCategoryId )||this.isInvalidValues(categoryOptions,this.state.documentCategoryId)){
      errors['documentCategoryId'] = ERRORS.required;
    }
    const documentTypeOptions = this.getDocumentTypeOptions();
    const { documentTypeId } = this.state;
    if ((documentTypeOptions.length && !documentTypeId )||this.isInvalidValues(documentTypeOptions,this.state.documentTypeId)) {
      errors['documentTypeId'] = ERRORS.required;
    }

    const subCategoryOptions = this.getSubCategoryOptions();
    if (subCategoryOptions.length === 0) {
      errors = _.omit(errors, 'documentSubCategoryId');
    }

    // User has to pick a refId for related libraries
    const { rootFolders } = this.props;
    const { refId, rootFolderId } = this.state;
    const library = _.findById(rootFolders, rootFolderId);
    if (library && library.slug !== 'application' && !refId) {
      errors['refId'] = ERRORS.required;
    }
    this.setState({ errors });
    return errors;
  }

  changeErrorState(field, value) {
    if (this.requiredFields.indexOf(field) !== -1) {
      const errors = { ...this.state.errors };
      if (value) delete errors[field];
      this.setState({ errors });
    }
  }

  handleChange = field => {
    return e => {
      const value = e.target.value;
      this.setState({ [field]: value });
      this.changeErrorState(field, value);
    };
  };

  getUpdated = () => {
    // Check for errors and return null if there are any
    const errors = this.validate();
    if (!_.isEmpty(errors)) return null;

    const toPick = ['id', 'keyId', 'blobUrl', 'rowVersion'];
    const toOmit = ['errors'];
    return {
      ..._.pick(this.props.doc, toPick),
      ..._.omit(this.state, toOmit),
    };
  };

  handleSelectChange(field) {
   
    
    return option => {
      const value = option ? option.value : null;
      const newState = { [field]: value };
      
      if (field === 'rootFolderId') {
        newState.documentCategoryId = null;
        newState.documentSubCategoryId = null;
        newState.documentTypeId = null;
        newState.refId = null;
        newState.slug = this.getSlug(this.props.rootFolders,value).slug;  
      } else if (field === 'documentCategoryId') {
        let docURA ={
          documentCategoryId: newState.documentCategoryId,
          documentCategory:{
            slug: this.getSlug(this.props.documentCategories,newState.documentCategoryId).slug
          },
        };
        newState.isDocUra = isDocURA(docURA);
        newState.documentTypeId = null;
        newState.documentSubCategoryId = null;
      } else if (field === 'displacementTypeId') {
        newState.documentFolderId = null;
        newState.documentSubFolderId = null;
      } else if (field === 'documentFolderId') {
        newState.documentSubFolderId = null;
      }
      this.setState(newState);

      this.handleChangeRequiredFields();

    };
  }

  // Returns { input, title }
  getStatic(field, name, id, titleField = 'title') {
    let input = null;
    let title = null;
    const values = this.props[field];

    if (values.length > 0) {
      const obj = _.findById(values, id);
      title = obj ? obj[titleField] : '';
      input = <Input name={name} readOnly={true} value={title} />;
    }
    return { input, title };
  }

  renderLibraryField() {
    const { privileges, currentUser, doc } = this.props;
    const { createdByEmployeeId, createdBy } = doc;
    const name = 'rootFolderId';
    const field = 'rootFolders';
    const value = this.state[name];
    const { input, title } = this.getStatic(field, name, value);
    const canMove =
      privileges.canMove ||
      (createdByEmployeeId && createdByEmployeeId === currentUser.id) ||
      createdBy === `${currentUser.firstName} ${currentUser.lastName}`;

    return canMove ? (
      <TSelect
        name={name}
        value={value}
        tooltip={title}
        options={this.getRootFolderOptions()}
        onChange={this.handleSelectChange(name)}
      />
    ) : (
      input
    );
  }
  isInvalidValues(listOfOptions,previousValue){
    let filteredList = listOfOptions.filter((object)=>{return(object.value === previousValue)});
    return filteredList.length === 0;
  }
  renderCategoryField({ canEditClassifyMetadata }) {
    const name = 'documentCategoryId';
    const field = 'documentCategories';

    const value = this.state[name];
    const { input, title } = this.getStatic(field, name, value);

    return canEditClassifyMetadata ? (
      <TSelect
        name={name}
        value={value}
        tooltip={title}
        options={this.getCategoryOptions()}
        onChange={this.handleSelectChange(name)}
      />
    ) : (
      input
    );
  }

  renderSubCategoryField({ canEditClassifyMetadata }) {
    const name = 'documentSubCategoryId';
    const field = 'documentSubCategories';
    const value = this.state[name];
    const { input, title } = this.getStatic(field, name, value);

    if (canEditClassifyMetadata) {
      return (
        <TSelect
          name={name}
          value={value}
          tooltip={title}
          options={this.getSubCategoryOptions()}
          onChange={this.handleSelectChange(name)}
        />
      );
    } else {
      return input;
    }
  }

  renderDocumentTypeField({ canEditClassifyMetadata }) {
    const name = 'documentTypeId';
    const field = 'documentTypes';
    const value = this.state[name];
    const { input, title } = this.getStatic(
      field,
      name,
      value,
      'documentTitle'
    );

    if (canEditClassifyMetadata) {
      return (
        <TSelect
          name={name}
          value={value}
          tooltip={title}
          options={this.getDocumentTypeOptions()}
          onChange={this.handleSelectChange(name)}
        />
      );
    } else {
      return input;
    }
  }

  renderClassifiedControl({ canEditClassifyMetadata }) {
    if (!canEditClassifyMetadata) return null;
    return (
      <div className="control">
        <label>HRO Classified</label>
        <TSelect
          name="documentTypeId"
          value={this.state.hroClassified}
          options={this.getClassifiedOptions()}
          onChange={this.handleSelectChange('hroClassified')}
        />
      </div>
    );
  }

  renderTenantIDField(errors) {
    if(this.props.tenantIds.length > 0){
     return(
      <div className="control">
      <label>Tenant ID</label>
      <TSelect
        name="tenantId"
        value={this.state.tenantId}
        options={this.getTenantIdsOptions()}
        onChange={this.handleSelectChange('tenantId')}
      />
      {this.renderError('tenantId')}
    </div>
     );
    }
    else{
      return (
        <div className={'control' + (errors.tenantId ? ' has-error' : '')}>
          <label>Tenant ID</label>
          <input
            name="tenantId"
            value={this.state.tenantId}
            onChange={this.handleChange('tenantId')}
          />
          {this.renderError('tenantId')}
        </div>
      );
    }

  }

  renderDisplacementTypeField() {
    return (
      <div className="control">
        <label>Displacement Type</label>
        <TSelect
          name="displacementTypeId"
          value={this.state.displacementTypeId}
          options={mapTitles(this.props.uraTypes)}
          onChange={this.handleSelectChange('displacementTypeId')}
        />
      </div>
    );
  }

  renderFolderField() {
    return (
      <div className="control">
        <label>URA Folder</label>
        <TSelect
          name="documentFolderId"
          value={this.state.documentFolderId}
          options={this.getDocumentFolderOptions()}
          onChange={this.handleSelectChange('documentFolderId')}
        />
      </div>
    );
  }

  renderSubFolderField() {
    return (
      <div className="control">
        <label>URA Sub-Folder</label>
        <TSelect
          name="documentSubFolderId"
          value={this.state.documentSubFolderId}
          options={this.getDocumentSubFolderOptions()}
          onChange={this.handleSelectChange('documentSubFolderId')}
        />
      </div>
    );
  }

  renderRefId() {
    const { doc, rootFolders } = this.props;
    const { rootFolderId, errors } = this.state;
    const library = _.findById(rootFolders, rootFolderId);

    if (!library || library.slug === 'application') {
      return null;
    } else if (doc.rootFolderId === rootFolderId) {
      return (
        <div className="control">
          <label>{library.refIdDisplayTitle}</label>
          <Input name="refId" value={doc.refId} readOnly={true} />
        </div>
      );
    } else {
      return (
        <div className={'control' + (errors.refId ? ' has-error' : '')}>
          <label>{library.refIdDisplayTitle}</label>
          <TSelect
            name="documentSubFolderId"
            value={this.state.refId}
            options={this.getRefIdOptions(rootFolderId)}
            onChange={this.handleSelectChange('refId')}
          />
          {this.renderError('refId')}
        </div>
      );
    }
  }

  renderParentTitle(doc, isManual) {
    if (!isManual) return null;
    return (
      <div className="control">
        <label>Parent Document Title</label>
        <div>{doc.parentFileTitle}</div>
      </div>
    );
  }

  renderReadonlyFields(doc, isManual) {
    return (
      <div>
        <div className="control">
          <label>{isManual ? 'Parent File Name' : 'Original File Name'}</label>
          <div>{getOriginalName(doc)}</div>
        </div>
      </div>
    );
  }

  renderApplicantNameField() {
    return (
      <div className="control">
        <label>Applicant Name</label>
        <input
          name="applicantName"
          value={this.state.applicantName}
          onChange={this.handleChange('applicantName')}
        />
      </div>
    );
  }

  renderDescriptionField() {
    return (
      <div className="control">
        <label>Document Description</label>
        <input
          name="documentDescription"
          value={this.state.documentDescription}
          onChange={this.handleChange('documentDescription')}
        />
      </div>
    );
  }

  renderError(field) {
    const { errors } = this.state;
    if (!errors[field]) return null;
    return <div className="error">{errors[field]}</div>;
  }

  render() {
    const { privileges, doc } = this.props;
    const { errors } = this.state;
    const isURA = this.state.isDocUra;
    const isPDR = isDocPDR(doc);
    const isManual = doc.documentCategory.slug === 'manual-classification';
    
    return (
      <div>
        <h6>Details</h6>
        <div className={'control' + (errors.documentName ? ' has-error' : '')}>
          <label>Document Name</label>
          <Input
            name="documentName"
            readOnly={this.state.slug === 'application'}
            value={this.state.documentName}
            onChange={this.handleChange('documentName')}
          />
          {this.renderError('documentName')}
        </div>
        <div className={'control' + (errors.rootFolderId ? ' has-error' : '')}>
          <label>Library</label>
          {this.renderLibraryField(privileges)}
          {this.renderError('rootFolderId')}
        </div>
        <div
          className={
            'control' + (errors.documentCategoryId ? ' has-error' : '')
          }
        >
          <label>Category</label>
          {this.renderCategoryField(privileges)}
          {this.renderError('documentCategoryId')}
        </div>
        <div
          className={
            'control' + (errors.documentSubCategoryId ? ' has-error' : '')
          }
        >
          <label>Sub Category</label>
          {this.renderSubCategoryField(privileges)}
          {this.renderError('documentSubCategoryId')}
        </div>
        <div
          className={'control' + (errors.documentTypeId ? ' has-error' : '')}
        >
          <label>Document Type</label>
          {this.renderDocumentTypeField(privileges)}
          {this.renderError('documentTypeId')}
        </div>
        {isPDR ? this.renderApplicantNameField() : null}
        {isPDR ? this.renderDescriptionField() : null}
        {isURA ? this.renderTenantIDField(errors) : null}
        {isURA ? this.renderDisplacementTypeField() : null}
        {isURA ? this.renderFolderField() : null}
        {isURA ? this.renderSubFolderField() : null}
        {this.renderClassifiedControl(privileges)}
        {this.renderRefId()}
        {privileges.canEditClassifyMetadata ? null : (
          <div className="control">
            <label>Library</label>
            <div>{doc.rootFolder.title}</div>
          </div>
        )}
        {this.renderReadonlyFields(doc, isManual)}
        {this.renderParentTitle(doc, isManual)}
        <UserDateData doc={doc} />
      </div>
    );
  }
}

import _ from 'utils/fp';

const PRIVILEGES = {
  canDelete: false,
  canDownload: false,
  canView: true, // This avoids flashing of permissione error messages
  canMove: false,
  canUpload: false,
  canEditBasicMetadata: false,
  canEditClassifyMetadata: false,
  canInitiateUpload: false,
};

export function getPrivileges(privileges, folderSlug) {
  return _.get(privileges, folderSlug, PRIVILEGES);
}

export function getDocPrivileges(privileges, folderSlug, doc) {
  if (doc.id && folderSlug === 'application')
    return getPrivileges(privileges, 'apl-' + doc.documentCategory.slug);
  return getPrivileges(privileges, folderSlug);
}

export function getResultPrivileges(privileges, result) {
  if (result.rootFolderSlug === 'application')
    return getPrivileges(privileges, 'apl-' + result.documentCategorySlug);
  return getPrivileges(privileges, result.rootFolderSlug);
}

export function canEditDocument(doc, privileges) {
  // If the document is HRO classified, only users with reclassify permission should be able to edit
  if (doc.hroClassified && !privileges.canEditClassifyMetadata) return false;

  return privileges.canEditBasicMetadata;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


export default class HomeNav extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  render () {
    const { url } = this.props.match
    return (
      <ul>
        <li className={url === '/' ? 'active' : null}>
          <Link to="/">Metrics this month</Link>
        </li>
        <li className={url === '/watchlist' ? 'active' : null}>
          <Link to="/watchlist">Watch List</Link>
        </li>
        <li className={url === '/recent' ? 'active' : null}>
          <Link to="/recent">Recently Viewed</Link>
        </li>
        <li className={url === '/saved-searches' ? 'active' : null}>
          <Link to="/saved-searches">Saved Searches</Link>
        </li>
        <li className={url === '/audit-log' ? 'active' : null}>
          <Link to="/audit-log">Audit Log</Link>
        </li>
      </ul>
    )
  }
}

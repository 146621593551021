export const MAX_UPLOAD_MB = 300;
export const MAX_RENDER_MB = 1500;

export const PERMITTED_EXTENSIONS = [
  'PDF',
  'TXT',

  // Microsoft Ofice
  'DOC',
  'DOCX',
  'XLS',
  'XLSX',
  'PPT',
  'PPTX',
  'MSG',

  // Images
  'TIFF',
  'JPG',
  'PNG',

  // Visio files
  'VSDX',
  'VSX',
  'VTX',
  'VDX',
  'VSSX',
  'VSTX',
  'VSDM',
  'VSSM',
  'VSTM',
  'VDW',
  'VSD',
  'VSS',
  'VST',
  'ESX',
];

import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import store from 'store';

import { getLocal, setLocal, removeLocal } from 'utils/storage';

const noop = () => null; 
let BASE_URL = 'https://dmsstg.cityofny.org:444/api/v1/';

if (process.env.REACT_APP_BUILD === 'production') {
  BASE_URL = '/api/v1/';
}

const LOGIN_URL = `${BASE_URL}account/signin`;
const LOGOUT_URL = `${BASE_URL}account/signout`;

const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'browser-fp': getLocal('browser-fp'),
  },
});

function redirectToLogin() {
  const { pathname, search } = window.location;
  setLocal('redirect_url', pathname + search);

  Fingerprint2.getV18(result => {
    setLocal('browser-fp', result);
    const qs = `?browserFingerPrint=${result}`;
    window.location.href = LOGIN_URL + qs;
  });
}

function redirectToLogout() {
  removeLocal('browser-fp');
  window.location.href = LOGOUT_URL;
}

function withAuth(config, success, failure = noop) {
  return client(config)
    .then(resp => {
      const { statusCode } = store.getState().homeStore;
      if (!statusCode)
        store.dispatch({ type: 'AUTH_SUCCESS', data: resp.status });
      success(resp);
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        return;
      } else if (error.response && error.response.status === 401) {
        redirectToLogin();
      } else {
        failure(error);
      }
    });
}

function dispatchWithAuth(dispatch, success = noop, failure = noop) {
  return (args, type) =>
    withAuth(
      { method: 'get', ...args },
      payload => {
        dispatch({ type, payload, ...args });
        success(payload);
      },
      error => failure(error)
    );
}

export { client, BASE_URL, withAuth, dispatchWithAuth, redirectToLogout };

import React from 'react';
import PropTypes from 'prop-types';

function DocumentHeading({ doc }) {
  return (
    <h4>
      {doc.isSplitResult && <span className="svg-Icon-Split" />}
      {doc.isMergeResult && <span className="svg-Icon-Merge" />}
      {doc.documentName}
    </h4>
  );
}
DocumentHeading.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default DocumentHeading;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import logo from 'images/Logo-BIB.png';
import Footer from 'components/global/Footer';
import UserProfile from 'components/global/UserProfile';
import SearchForm from 'components/global/SearchForm';

import Metrics from './Metrics';
import WatchList from './WatchList';
import Recent from './Recent';
import AuditLog from './AuditLog';
import SavedSearches from './SavedSearches';
import HomeNav from './HomeNav';


class Home extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      redirect: null
    }
  }

  handleSearch = (query) => {
    const redirect = '/search/' + encodeURIComponent(query);
    this.setState({ redirect });
  }

  render () {
    const { redirect } = this.state;
    if (redirect) return <Redirect to={redirect} />

    return (
      <div className="home">

        <div className="home-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col s6">
                <h2><Link to="/"><img src={logo} alt="Build It Back"/></Link></h2>
              </div>
              <div className="col s6 text-right">
                <UserProfile />
              </div>
            </div>
          </div>
        </div>

        <div className="home-search">
          <div className="container">
            <div className="row">
              <div className="col s12">
                <h1 className="heading">Document Management System</h1>
                <SearchForm onSubmit={this.handleSearch} />
              </div>
            </div>
          </div>
        </div>

        <div className="home-nav">
          <div className="container-fluid">
            <Route path="/(|watchlist|recent|saved-searches|audit-log)" render={props =>
              <HomeNav match={props.match} />
            }/>
          </div>
        </div>

        <div className="home-sections">
          <div className="container-fluid">
            <Switch>
              <Route exact path="/" component={(props) => <Metrics {...props}/>} />
              <Route exact path="/watchlist" component={(props) => <WatchList {...props} />} />
              <Route exact path="/recent" component={(props) => <Recent {...props} />} />
              <Route exact path="/saved-searches" component={(props) => <SavedSearches {...props} />} />
              <Route exact path="/audit-log" component={(props) => <AuditLog {...props} />} />
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Home;

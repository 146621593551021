import _ from 'utils/fp';


function fileNameFromUrl(url) {
  const name = _.last(url.split('/'));
  return decodeURIComponent(name);
}

export function getOriginalName({ originalFileName, sourceUrl }) {
  if (originalFileName) return originalFileName;
  return sourceUrl ? fileNameFromUrl(sourceUrl) : '';
}

export function isDocURA(doc) {
  if (!doc.documentCategoryId) return false;
  return doc.documentCategory.slug === 'uniform-relocation-assistance';
}

export function isDocPDR(doc) {
  if (!doc.rootFolderId) return false;
  return doc.rootFolder.slug === 'portal-document-review';
}

export function mapTitles(list) {
  return list.map(item => ({ label: item.title, value: item.id }));
}

export function getDocumentType(doc, isManual) {
  if (isManual) return doc.parentFileDocumentType;
  return doc.documentType ? doc.documentType.documentTitle : null;
}

export function folderNeedsSubCategory(folderSlug) {
  // Subcategory is not required for the following directories

  return !(['design-consultation', 'tra'].includes(folderSlug));
}

export function getRotateTransform(deg,url='') {
  const value = deg || 0;
  let img = new Image();
  img.src = url;

  let hori = img.width> img.height;
  
  if(hori){
    let transform = `rotate(${value || 0}deg)`;
    if (value % 180) transform += ' scale(1.3)';
    return {transform};
  }

  let transform = `rotate(${value || 0}deg)`;

  if (value % 180) transform += ' scale(.75)';

  return { transform,'ms-transform':transform };
}

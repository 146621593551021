export function getLocal (name) {
  return window.sessionStorage.getItem(name);
}

export function setLocal (name, value) {
  return window.sessionStorage.setItem(name, value);
}

export function removeLocal (name) {
  return window.sessionStorage.removeItem(name);
}

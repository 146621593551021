import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import {
  fetchDocument,
  fetchCategories,
  deleteDocument,
  modifyDocument,
  addToViewedDocuments,
} from 'actions/appsActions';
import { getDocPrivileges, canEditDocument } from 'utils/privileges';
import ERRORS from './errors';
import _ from 'utils/fp';
import { getRotateTransform } from 'utils/documents';

import IconButton from 'components/global/IconButton';
import Hamburger from 'components/global/Hamburger';
import DocumentPreview from './DocumentPreview';
import DocumentHeading from './DocumentHeading';
import SplitForm from './SplitForm';
import Metadata from './Metadata';

class DocumentDetail extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    keyId: PropTypes.string.isRequired,
    folderSlug: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,

    privileges: PropTypes.object.isRequired,
    categoriesLoading: PropTypes.bool.isRequired,
    document: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    fetchDocument: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    addToViewedDocuments: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    toggleSideMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSplitting: false,
      isReordering: false,
      successText: '',
      errorText: '',
      toDelete: null,
      pageCount:0, 
      isDocumentStillInProcess:true,
      reorderParams: {
        pages: [],
        page: null,
      },
    };
  }

  componentDidMount() {
    this.fetchDocument(this.props);

  }

  fetchDocument({ keyId, folderSlug, documentId }) {
    const { addToViewedDocuments, fetchDocument } = this.props;
    addToViewedDocuments(documentId);
    fetchDocument(keyId, folderSlug, documentId);
  }

  renderPermissionError() {
    if (this.props.categoriesLoading) return null;
    return (
      <div className="document-detail">
        <div className="col s12 text-center">
          <div className="no-results">{ERRORS.noViewPermissions}</div>
        </div>
      </div>
    );
  }

  closeView = () => {
    const { keyId, folderSlug, history } = this.props;
    const backUrl = window.lastListPath || `/documents/${keyId}/${folderSlug}`;
    history.push(backUrl);
  };
  handleUpdatedPreview({pageCount,isDocumentStillInProcess}){
    this.setState({pageCount:pageCount, isDocumentStillInProcess:isDocumentStillInProcess})
  }
  onDelete = ({ keyId, id }) => {
    this.closeModal();
    this.setState({ isLoading: true });

    deleteDocument(
      keyId,
      id,
      () => {
        this.setState({
          succcessText: ERRORS.deleteSuccess,
          isLoading: false,
        });
        this.props.fetchCategories(this.props.keyId);
      },
      this.notifyDeleteError
    );
  };

  notifyDeleteError = error => {
    let errorText = _.get(error, 'response.data.message');
    if (!errorText) errorText = ERRORS['genericDelete'];
    this.setState({
      errorText,
      isLoading: false,
    });
  };

  renderEditButton() {
    const url = this.props.match.url.replace('/view/', '/edit/');
    return (
      <IconButton icon="Edit" className="btn-action" isLink={true} to={url} />
    );
  }

  renderModifyButtons() {
    console.log(this.props.privileges)
    let { pageCount, isDocumentStillInProcess } = this.state;
    const disabledSplit = [-1, 1].includes(pageCount) || isDocumentStillInProcess;
    const disabledRearrange = pageCount < 1 || isDocumentStillInProcess;
    const canEdit = canEditDocument(this.props.document, this.props.privileges);
    const {canUpload} = this.props.privileges;
    return (
      <>
        <IconButton
          altname="Rearrange and Rotate"
          icon="Page"
          className="btn-action"
          disabled={disabledRearrange||!canEdit}
          onClick={() => this.setState({ isReordering: true })}
        />
        <IconButton
          icon="Split"
          className="btn-action"
          disabled={disabledSplit||!canUpload}
          onClick={() => this.setState({ isSplitting: true })}
        />
      </>
    );
  }

  closeModal = () => {
    this.setState({
      isLoading: false,
      successText: '',
      errorText: '',
      toDelete: null,
    });
  };

  renderConfirmModal(doc) {
    return (
      <div className="modal alert-modal">
        <div className="modal-fade" />
        <div className="modal-content">
          <p>Are you sure you want to delete this document?</p>
          <button onClick={() => this.onDelete(doc)}>Yes</button>
          <button onClick={this.closeModal}>No</button>
        </div>
      </div>
    );
  }

  renderModal() {
    const { errorText, succcessText } = this.state;
    const onClick = succcessText ? this.closeView : this.closeModal;

    if (!errorText && !succcessText) return null;
    return (
      <div className="modal alert-modal">
        <div className="modal-fade" />
        <div className="modal-content">
          <p>{errorText || succcessText}</p>
          <button onClick={onClick}>Close</button>
        </div>
      </div>
    );
  }

  renderPrintButton() {
    return (
      <IconButton
        icon="Print"
        className="btn-action"
        onClick={() => this.preview.print()}
      />
    );
  }

  renderDownloadButton(doc) {
    return (
      <IconButton
        icon="Download"
        className="btn-action"
        onClick={() => this.props.onDownload(doc)}
      />
    );
  }

  renderDeleteButton(doc) {
    const { privileges, currentUser } = this.props;
    const { createdByEmployeeId, createdBy } = doc;
    const canDelete =
      privileges.canDelete ||
      (createdByEmployeeId && createdByEmployeeId === currentUser.id) ||
      createdBy === `${currentUser.firstName} ${currentUser.lastName}`;
    if (!canDelete) return null;

    return (
      <IconButton
        icon="Delete"
        className="btn-action"
        onClick={() => this.setState({ toDelete: doc })}
      />
    );
  }

  splitDocument = (start, end) => {
    const { history, match } = this.props;
    const url = match.url.replace('/view/', '/edit/');
    const queryString = qs.stringify({
      split: 1,
      startPageNumber: start,
      endPageNumber: end,
    });
    history.push(url + '?' + queryString);
  };

  postReorder = () => {
    const { document: doc } = this.props;
    const { pages } = this.state.reorderParams;

    const data = pages.map(page => ({
      id: page.id,
      documentId: doc.id,
      oldPageNumber: page.pageNumber,
      newPageNumber: page.newPageNumber || page.pageNumber,
      rotationDegrees: page.rotate || 0,
    }));

    modifyDocument(doc.keyId, doc.id, data, () => this.closeView());
  };

  renderCloseButton = () => {
    return (
      <IconButton
        icon="Close"
        className="btn-action"
        onClick={this.closeView}
      />
    );
  };

  renderHeader() {
    const { document: doc, privileges } = this.props;
    const { isLoading, isSplitting, isReordering } = this.state;
    const canEdit = canEditDocument(doc, privileges);
    const {closeoutLockEnabled} = privileges;
    return (
      <div className="document-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col s8 scrollable">
              <Hamburger onClick={() => this.props.toggleSideMenu(true)} />
              <DocumentHeading doc={doc} />
            </div>
            <div className="col s4 actions">
              {isLoading ? (
                <button className="btn-load">
                  <span className="loader" />
                </button>
              ) : isSplitting ? (
                <SplitForm
                  pageCount={doc.pageCount}
                  splitDocument={this.splitDocument}
                  onCancel={() => this.setState({ isSplitting: false })}
                />
              ) : isReordering ? (
                <>
                  <button className="btn-primary" onClick={this.postReorder}>
                    Save
                  </button>
                  {this.renderCloseButton()}
                </>
              ) : closeoutLockEnabled ? (
                <>{this.renderCloseButton()}</>
              ):(
                <>
                  {this.renderModifyButtons()}
                  {privileges.canPrint && this.renderPrintButton(doc)}
                  {privileges.canDownload && this.renderDownloadButton(doc)}
                  {canEdit && this.renderEditButton()}
                  {this.renderDeleteButton(doc)}
                  {this.renderCloseButton()}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  setReorderPage = reorderParams => {
    this.setState({ reorderParams });
  };

  renderPages() {
    const { document: doc } = this.props;
    const { reorderParams, isReordering } = this.state;

    const { page } = reorderParams;
    const setReorderPage = isReordering ? this.setReorderPage : null;
    const previewStyle = getRotateTransform(page && page.rotate);

    return (
      <div className="document-preview reordering">
        <div className="col s12 m6 small-thumbs">
          <DocumentPreview notifyParent={this.handleUpdatedPreview.bind(this)} document={doc} onPageChange={setReorderPage} />
        </div>
        <div className="col s12 m6 page-preview">
          <div style={previewStyle}>
            {page && page.largeThumbnailUrl ? (
              <img src={page.largeThumbnailUrl} alt="thumb" />
            ) : (
              <div className="loading-page">
                <div className="loader" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderDetail() {
    const { document: doc, privileges } = this.props;
    return (
      <>
        <div className="col s12 m4 pull-right" ref={el => (this.sidebar = el)}>
          <div className="document-metadata">
            <Metadata doc={doc} privileges={privileges} />
          </div>
        </div>
        <div className="col s12 m8">
          <div className="document-preview">
            <DocumentPreview notifyParent={this.handleUpdatedPreview.bind(this)} ref={el => (this.preview = el)} document={doc} />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { document: doc, privileges, documentId } = this.props;
    const { errorText, isReordering, succcessText, toDelete } = this.state;
    const showDoc = doc.id === parseInt(documentId, 10);
    if (!privileges.canView) {
      return this.renderPermissionError();
    } else if (errorText || succcessText) {
      return this.renderModal();
    }

    return (
      <div className="document-detail">
        <a href="/#" download ref={link => (this.downloadLink = link)}>
          Download
        </a>
        {showDoc ? this.renderHeader() : null}
        <div className="document-main">
          <div className="container-fluid">
            <div className="row no-gutters">
              {showDoc &&
                (isReordering ? this.renderPages() : this.renderDetail())}
            </div>
          </div>
        </div>
        {toDelete ? this.renderConfirmModal(toDelete) : null}
      </div>
    );
  }
}

function mapStateToProps(state, { folderSlug }) {
  const { document: doc, privileges } = state.appStore;
  return {
    privileges: getDocPrivileges(privileges, folderSlug, doc),
    categoriesLoading: state.appStore.categoriesLoading,
    document: doc,
    currentUser: state.homeStore.claims,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchDocument,
    fetchCategories,
    addToViewedDocuments,
  }
)(DocumentDetail);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  fetchSearchResults,
  changeWatch,
  updateWatchIn,
  registerSearchClick,
} from 'actions/homeActions';
import { getSas } from 'actions/sas';
import { ListCountMetadata } from 'utils/propTypes';
import { getLocal, setLocal } from 'utils/storage';
import { DOCUMENT_TITLE, shouldNavigate } from 'utils';

import logo from 'images/Logo-BIB.png';
import PDFDEFAULT from '../../flat-document.svg';
import hroLogo from 'images/Logo-HRO.png';
import IconButton from 'components/global/IconButton';
import SaveSearchButton from 'components/global/SaveSearchButton';
import Footer from 'components/global/Footer';
import UserProfile from 'components/global/UserProfile';
import { SearchFilter } from 'components/global/SearchFilter';
import ColumnSelector from 'components/global/ColumnSelector';
import { Pagination, PAGE_SIZE } from 'components/global/Pagination';

import Modal from 'components/global/Modal';

const HIDE_COLS_KEY = 'hiddenCols';

class Search extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    searchResults: ListCountMetadata.isRequired,
    lastSearchQuery: PropTypes.string.isRequired,
    fetchSearchResults: PropTypes.func.isRequired,
    updateWatchIn: PropTypes.func.isRequired,
    viewedDocuments: PropTypes.instanceOf(Set).isRequired,
  };

  constructor(props) {
    super(props);
    const currentView = getLocal('searchView') || 'list';
    const hiddenCols = JSON.parse(getLocal(HIDE_COLS_KEY)) || [5, 6];
    this.state = {
      isLoading: true,
      currentView,
      currentPage: 0,
      showColumnNames: false,
      hiddenCols,
      filters: {},
      sortProp: null,
      sortOrder: 'desc',
      showModal:false,
    };

    this.columns = [
      'APP ID',
      'Library',
      'Category',
      'Document Type',
      'Document Name',
      'Created By',
      'Modified By',
    ];
  }

  componentDidMount() {
    this.fetchResults(0);
    document.title = `${DOCUMENT_TITLE} | Search`;
    this.changeCurrentView(this.state.currentView);
  }

  changeCurrentView(viewType) {
    getSas().then(() => {
      this.setState({
        currentView: viewType,
      });
      setLocal('searchView', viewType);
    });
  }

  hideColumns = hiddenCols => {
    setLocal(HIDE_COLS_KEY, JSON.stringify(hiddenCols));
    this.setState({ hiddenCols, showColumnNames: false });
  };


  hideModalFunc = () =>{this.setState({showModal:false})}

  getThumb(doc) {
    const src = doc.thumbBlobUrl;
    return <img src={!!src?src:PDFDEFAULT}  alt={doc.documentName} onError={(e)=>{e.target.src = PDFDEFAULT}} />;
  }

  getQuery() {
    const { query } = this.props.match.params;
    return decodeURIComponent(query);
  }

  getParams(page) {
    const { sortProp, sortOrder } = this.state;
    const params = { skip: page * PAGE_SIZE };
    if (sortProp) {
      params.orderBy = sortProp;
      params.orderByDirection = sortOrder;
    }
    return params;
  }

  fetchResults(page) {
    const params = this.getParams(page);
    const { filters } = this.state;
    filters.query = this.getQuery();

    this.setState({ isLoading: true });
    this.props.fetchSearchResults(params, filters, (response) => {
      this.setState({ isLoading: false, currentPage: page });

      if(response.data.count < 1){
        this.setState({showModal:true})
      }     

    });
   

  }

  changeWatchFor({ keyId, inWatchList }) {
    changeWatch(inWatchList, keyId, () => {
      this.props.updateWatchIn('SEARCH', keyId, !inWatchList);
    });
  }

  getDetailUrl({ id, keyId, rootFolderSlug }) {
    return `/documents/${keyId}/${rootFolderSlug}/view/${id}`;
  }

  goToDocument(result, pos) {
    const { metadata } = this.props.searchResults;
    registerSearchClick(metadata.searchId, result.id, pos);
    this.props.history.push(this.getDetailUrl(result));
  }

  onItemClick(result, pos) {
    return e => {
      const isIcon = e.target.className.indexOf('svg-Icon') !== -1;
      if (!isIcon) this.goToDocument(result, pos);
    };
  }

  onRowClick(result, pos) {
    return e => {
      if (shouldNavigate(e.target)) {
        this.goToDocument(result, pos);
      }
    };
  }

  setSorter = property => {
    return () => {
      // Toggle sortOrder if sorting on the same property
      // Otherwise, just use ascending as the default
      const { sortProp, sortOrder } = this.state;
      const toggled = sortOrder === 'asc' ? 'desc' : 'asc';
      const newOrder = sortProp === property ? toggled : 'asc';
      this.setState({ sortProp: property, sortOrder: newOrder }, () =>
        this.fetchResults(0)
      );
    };
  };

  getSortHead(property, label, xl) {
    // Show asc desc sort icon only for current sortProp
    const { sortProp, sortOrder } = this.state;
    const sortClass = sortProp === property ? sortOrder : '';
    return (
      <th className={xl ? 'xl ' : ''} onClick={this.setSorter(property)}>
        <span className={sortClass}>{label}</span>
      </th>
    );
  }

  renderPagination(position) {
    const { count, entries } = this.props.searchResults;
    const { currentPage } = this.state;
    const paginationProps = {
      count,
      position,
      currentPage,
      loaded: entries.length > 0,
      fetch: page => this.fetchResults(page),
    };
    return <Pagination {...paginationProps} />;
  }

  renderViewButton(result, pos) {
    const { viewedDocuments } = this.props;
    return (
      <IconButton
        icon="View"
        active={viewedDocuments.has(result.id)}
        onClick={() => this.goToDocument(result, pos)}
      />
    );
  }

  renderWatchlistButton(result) {
    return (
      <IconButton
        icon="Watchlist"
        title={result.inWatchList ? 'Unwatch' : 'Watch'}
        className={result.inWatchList ? 'active' : ''}
        onClick={() => this.changeWatchFor(result)}
      />
    );
  }

  renderActions() {
    const { currentView, showColumnNames, hiddenCols } = this.state;

    return (
      <div className="actions">
        {showColumnNames && (
          <ColumnSelector
            columns={this.columns}
            hiddenCols={hiddenCols}
            onDone={cols => this.hideColumns(cols)}
            onCancel={() => this.setState({ showColumnNames: false })}
          />
        )}
        <button
          title="Add/Remove Columns"
          className={'show-cols' + (showColumnNames ? ' active' : '')}
          onClick={() => this.setState({ showColumnNames: true })}
        >
          ▥
        </button>
        <IconButton
          icon="List"
          title="Show list"
          className={
            'btn-icon show-list' + (currentView === 'list' ? ' active' : '')
          }
          onClick={() => this.changeCurrentView('list')}
        />
        <IconButton
          icon="Thumbnails"
          title="Show thumbnails"
          className={
            'btn-icon show-thumbs' + (currentView === 'thumbs' ? ' active' : '')
          }
          onClick={() => this.changeCurrentView('thumbs')}
        />
      </div>
    );
  }

  renderThumbsTable(entries) {
    return (
      <div className="thumbs-grid">
        {entries.map((result, pos) => (
          <div
            key={result.id}
            className="item-wrap"
            onClick={this.onItemClick(result, pos)}
          >
            <div className="item">
              <div className="thumb">{this.getThumb(result)}</div>
              <div className="details">
                <strong>{result.documentName}</strong>
                <span>APP ID: {result.keyId}</span>
                <span>Library: {result.rootFolderTitle}</span>
                <span>Category: {result.category}</span>
                <span>Document Type: {result.documentType}</span>
                <div>
                  {this.renderViewButton(result, pos)}
                  {this.renderWatchlistButton(result)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  onFilter(field) {
    return text => {
      const { filters } = this.state;
      if (text.length === 0) delete filters[field];
      else filters[field] = text;
      this.setState({ filters });
    };
  }

  submitFilters = () => {
    this.fetchResults(0);
  };

  renderListTable(entries) {
    return (
      <div className="table-wrap sortable">
        <table>
          <thead>
            <tr>
              {this.getSortHead('KeyId', 'APP ID')}
              {this.getSortHead('RootFolderTitle', 'Library')}
              {this.getSortHead('Category', 'Category')}
              {this.getSortHead('DocumentType', 'Document Type')}
              {this.getSortHead('DocumentName', 'Document Name')}
              {this.getSortHead('CreatedBy', 'Created By')}
              {this.getSortHead('ModifiedBy', 'Modified By')}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onKeyPress={e => e.key === 'Enter' && this.submitFilters()}>
              <SearchFilter onFilter={this.onFilter('keyIdPartial')} />
              <SearchFilter onFilter={this.onFilter('rootFolderTitle')} />
              <SearchFilter onFilter={this.onFilter('category')} />
              <SearchFilter onFilter={this.onFilter('documentType')} />
              <SearchFilter onFilter={this.onFilter('documentName')} />
              <SearchFilter onFilter={this.onFilter('createdBy')} />
              <SearchFilter onFilter={this.onFilter('modifiedBy')} />
              <td className="search-filter">
                <button onClick={this.submitFilters}>Search</button>
              </td>
            </tr>
            {entries.map((result, pos) => (
              <tr key={result.id} onClick={this.onRowClick(result, pos)}>
                <td>{result.keyId}</td>
                <td>{result.rootFolderTitle}</td>
                <td>{result.category}</td>
                <td>{result.documentType}</td>
                <td className="bw">{result.documentName}</td>
                <td>{result.createdBy}</td>
                <td>{result.modifiedBy}</td>
                <td>
                  {this.renderViewButton(result, pos)}
                  {this.renderWatchlistButton(result)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderTable() {
    const { entries } = this.props.searchResults;
    const { isLoading, currentView, hiddenCols } = this.state;

    const showThumbs = currentView === 'thumbs';
    if (entries.length === 0 && isLoading){
      return (
        <div className="results-wrap">
          <span className="loader" />
        </div>
      );
    }

    const resultsClassName = [
      'results-wrap',
      ...hiddenCols.map(c => `hide-col-${c + 1}`),
    ].join(' ');
    
    return (
      <div className={resultsClassName} onClick={this.hideModalFunc} >
        {isLoading ? <span className="loader" /> : null}

        <Modal show={this.state.showModal} onClose={this.hideModalFunc}>
          There are no matching results for the given search condition(s).
          <section> Please try again.</section>
        </Modal>
        
        {showThumbs
          ? this.renderThumbsTable(entries)
          : this.renderListTable(entries)}
      </div>
    );
  
  }

  render() {
    const query = this.getQuery();
    return (
      <div className="search">
        <div className="search-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col s4">
                <h2>
                  <Link to="/">
                    <img src={logo} alt="Build It Back" />
                    <span>Home</span>
                  </Link>
                </h2>
              </div>
              <div className="col s4 text-center">
                <img src={hroLogo} alt="NYC Recovery" />
              </div>
              <div className="col s4 text-right">
                <UserProfile />
              </div>
            </div>
          </div>
        </div>

        <div className="search-results">
          <div className="container">
            <div className="row">
              <div className="with-actions col s12">
                <h4>
                  Search results for <span>{query}</span>
                </h4>
                <SaveSearchButton searchQuery={query} />
                {this.renderActions()}
              </div>
              {this.renderPagination('top')}
              <div className="col s12">{this.renderTable()}</div>
              {this.renderPagination('bottom')}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSearchQuery: state.homeStore.lastSearchQuery,
    searchResults: state.homeStore.searchResults,
    viewedDocuments: state.appStore.viewedDocuments,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchSearchResults,
    updateWatchIn,
  }
)(Search);

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import logo from 'images/Logo-BIB.png';
import { setClaims } from 'actions/homeActions';
import { redirectToLogout } from 'actions';
import { getLocal } from 'utils/storage';

export const Logout = props => {
  redirectToLogout(props);
};

export const Login = () => {
  const toPath = getLocal('redirect_url') || '/';
  return <Redirect to={toPath} />;
};

Login.propTypes = {
  location: PropTypes.object.isRequired,
};

export class LogoutSuccess extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  onHomeClick = () => {
    this.props.history.push('/');
    setClaims(); // Force login
  };

  render() {
    return (
      <div className="static has-shadow">
        <div className="container-fluid">
          <div className="row">
            <div className="col s12">
              <h2>
                <button onClick={this.onHomeClick}>
                  <img src={logo} alt="Build It Back" />
                </button>
              </h2>
              <p>You have logged out of the application.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { combineReducers} from 'redux';

import appsReducer from './appsReducer';
import homeReducer from './homeReducer';

const reducers = {
  appStore: appsReducer,
  homeStore: homeReducer,
};

export default combineReducers(reducers);

import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'images/Logo-BIB.png';
import Footer from 'components/global/Footer';
import UserProfile from 'components/global/UserProfile';


export default class NotFound extends React.Component {
  render () {
    return (
      <div className="home">
        <div className="home-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col s6">
                <h2><Link to="/"><img src={logo} alt="Build It Back"/></Link></h2>
              </div>
              <div className="col s6">
                <UserProfile username="Zubin Wadia" />
              </div>
            </div>
          </div>
        </div>

        <div className="home-search">
          <div className="container">
            <div className="row">
              <div className="col s12">
                <h1 className="heading">Page Not Found</h1>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {Route } from 'react-router-dom';

import Footer from 'components/global/Footer';
import { getSas } from 'actions/sas';

import SideMenu from './SideMenu';
import DetailMain from './DetailMain';
import AppDetailHeader from './AppDetailHeader'; 



export default class AppDetail extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      sideMenuOpened: false,
      blobUrl: '',
      pdfUrl: '',
    };
  }

  handleSearch = query => {
    const { keyId } = this.props.match.params;
    const url = `/documents/${keyId}/search/` + encodeURIComponent(query);
    this.props.history.push(url);
  };

  toggleSideMenu = value => {
    this.setState({ sideMenuOpened: value });
  };

  triggerDownload = ({ blobUrl, pdfUrl }) => {
    if (pdfUrl && blobUrl !== pdfUrl) {
      this.setState({ blobUrl, pdfUrl });
    } else {
      this.startDownload(blobUrl);
    }
  };

  onError = errorText => {
    this.setState({ errorText });
  };

  closeModal = () => {
    this.setState({
      blobUrl: '',
      previewUrl: '',
      errorText: '',
    });
  };

  startDownload(url) {
    getSas().then(() => {
      this.downloadLink.href = url;
      this.downloadLink.click();
      this.closeModal();
    });
  }

  renderErrorModal() {
    const { errorText } = this.state;
    if (!errorText) return null;
    return (
      <div className="modal alert-modal">
        <div className="modal-fade" />
        <div className="modal-content">
          <p>{errorText}</p>
          <button onClick={this.closeModal}>OK</button>
        </div>
      </div>
    );
  }

  renderDownloadModal() {
    const { blobUrl, pdfUrl } = this.state;
    if (!(blobUrl && pdfUrl)) return null;
    return (
      <div className="modal download-modal">
        <div className="modal-fade" />
        <div className="modal-content">
          <button onClick={() => this.startDownload(blobUrl)}>
            Download Original File
          </button>
          <button onClick={() => this.startDownload(pdfUrl)}>
            Download PDF
          </button>
          <button className="close" onClick={this.closeModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { keyId, query } = this.props.match.params;
    const { sideMenuOpened } = this.state;
    return (
      <div className={'app-detail' + (sideMenuOpened ? ' menu-opened' : '')}>
        <div className="header">
          <div className="container-fluid">        
            <AppDetailHeader keyId={keyId} query={query} onSubmit={this.handleSearch}/>
          </div>
        </div>

        {this.renderErrorModal()}

        <Route
          path="/documents/:keyId/:folderSlug?/:query?"
          render={props => {
            const { keyId, folderSlug, query } = props.match.params;
            console.log(props)
            return (
              <SideMenu
                keyId={keyId}
                folderSlug={folderSlug || 'auto'}
                query={query || 'auto'}
                onPermissionError={this.onError}
                toggleSideMenu={this.toggleSideMenu}
              />
            );
          }}
        />

        <div className="faux-bg" />
        <a
          href="/#"
          target="_blank"
          ref={link => (this.downloadLink = link)}
          download
        >
          Download
        </a>
        {this.renderDownloadModal()}

        <DetailMain
          match={this.props.match}
          toggleSideMenu={this.toggleSideMenu}
          triggerDownload={this.triggerDownload}
        />

        <Footer />
      </div>
    );
  }
}

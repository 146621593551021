import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { shouldNavigate } from 'utils';
import { ListAndCount } from 'utils/propTypes';
import { fetchWatchList, changeWatch } from 'actions/homeActions';
import IconButton from 'components/global/IconButton';


class WatchList extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    watchList: ListAndCount.isRequired,
    fetchWatchList: PropTypes.func.isRequired,
  }

  componentDidMount () {
    this.props.fetchWatchList();
  }

  changeWatchFor = ({ keyId }) => {
    changeWatch(true, keyId, () => {
      this.props.fetchWatchList();
    });
  }

  renderRowButtons (entry) {
    const url = `/documents/${entry.keyId}`;
    return (
      <td>
        <IconButton icon="View" isLink={true} to={url} />
        <IconButton
          icon="Watchlist"
          title="UnWatch"
          className="active"
          onClick={() => this.changeWatchFor(entry)}
        />
      </td>
    )
  }

  onRowClick (entry) {
    return (e) => {
      if (shouldNavigate(e.target)) {
        this.props.history.push(`/documents/${entry.keyId}`);
      }
    }
  }

  renderTable () {
    const { entries } = this.props.watchList;
    return(
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>APP ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {entries.map(entry =>
              <tr key={entry.id} onClick={this.onRowClick(entry)}>
                <td>{entry.keyId}</td>
                {this.renderRowButtons(entry)}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render () {
    return (
      <div className="row">
        <div className="col s12">
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    watchList: state.homeStore.watchList,
  }
}

export default connect(mapStateToProps, {
  fetchWatchList,
})(WatchList);

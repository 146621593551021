import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { postDocument, checkExists } from 'actions/appsActions';
import { getSas } from 'actions/sas';
import _ from 'utils/fp';
import { mapTitles, folderNeedsSubCategory } from 'utils/documents';
import ERRORS from './errors';
import { Input, TSelect } from '../global/Input'; // with tooltips

export default class UploadForm extends React.Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    folderSlug: PropTypes.string.isRequired,
    refId: PropTypes.string.isRequired,
    uraCategoryId: PropTypes.number.isRequired,
    refIdDisplayTitle: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,

    documentCategories: PropTypes.array.isRequired,
    documentSubCategories: PropTypes.array.isRequired,
    documentTypes: PropTypes.array.isRequired,
    rootFolders: PropTypes.array.isRequired,
    uraTypes: PropTypes.array.isRequired,
    refIdOptions: PropTypes.array.isRequired,
    privileges: PropTypes.object.isRequired,

    onCancel: PropTypes.func.isRequired,
    onUploaded: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      progressText: '',
      errorText: '',
      existingDocument: null,
      isLoading: false,
      documentName: props.file.name,
      documentTypeId: null,
      documentCategoryId: null,
      documentSubCategoryId: null,
      displacementTypeId: null,
      documentFolderId: null,
      documentSubFolderId: null,
      tenantId: '',
      isURA: false,
      refId: props.refId || '',
      rootFolderId: '',
      rootFolderTitle: '',
      checkingExists: false,
    };

    this.requiredFields = ['documentCategoryId', 'documentTypeId'];
    if (props.folderSlug !== 'application') {
      this.requiredFields.push('refId');
    }
    if (folderNeedsSubCategory(props.folderSlug)) {
      this.requiredFields.push('documentSubCategoryId');
    }
  }

  componentDidMount() {
    for (const folder of this.props.rootFolders) {
      if (folder.slug === this.props.folderSlug) {
        this.setState({
          rootFolderId: folder.id,
          rootFolderTitle: folder.title,
        });
      }
    }
    this.setAutoCategory(this.state.rootFolderId);
  }

  isValid() {
    const requiredFields = this.getSubCategoryOptions().length
      ? [...this.requiredFields]
      : this.requiredFields.filter(field => field !== 'documentSubCategoryId');
    if (this.state.isURA) requiredFields.push('tenantId');
    return _.every(_.pick(this.state, requiredFields));
  }

  onSourceExists = ({ exists, document: doc }) => {
    let newState = { checkingExists: false };
    if (exists) {
      newState.existingDocument = doc;
      newState.errorText = ERRORS['exists'];
    }
    this.setState(newState);
  };

  checkSourceExists = sas => {
    const { keyId } = this.props;
    const { rootFolderId, documentCategoryId } = this.state;
    if (!documentCategoryId) return;

    const sourceUrl = this.getBlobStorageUrl(sas, documentCategoryId);
    const data = {
      keyId,
      sourceUrl,
      rootFolderId,
      blobUrl: sourceUrl,
    };
    this.setState({ checkingExists: true });
    checkExists(keyId, data, this.onSourceExists, this.notifyError);
  };

  getBlobStorageUrl(sas, documentCategoryId) {
    const { keyId, file, privileges, documentCategories } = this.props;
    const documentCategory = _.findById(documentCategories, documentCategoryId);

    return [
      sas.folderUri,
      privileges.entityName,
      encodeURIComponent(keyId),
      encodeURIComponent(documentCategory.folderName),
      encodeURIComponent(file.name),
    ].join('/');
  }

  getDocumentFolderOptions() {
    const { displacementTypeId } = this.state;
    const displacementType = _.findById(
      this.props.uraTypes,
      displacementTypeId
    );
    return displacementType ? mapTitles(displacementType.documentFolders) : [];
  }

  getDocumentSubFolderOptions() {
    const { displacementTypeId, documentFolderId } = this.state;
    const displacementType = _.findById(
      this.props.uraTypes,
      displacementTypeId
    );
    if (!displacementType) return [];
    const documentFolder = _.findById(
      displacementType.documentFolders,
      documentFolderId
    );
    return documentFolder ? mapTitles(documentFolder.documentSubFolders) : [];
  }

  getFolderOptions() {
    const options = this.props.rootFolders.map(folder => {
      return { label: folder.title, value: folder.id };
    });
    return _.iSortBy(options, 'label');
  }

  getCategoryOptions(rootFolderId) {
    const options = this.props.documentCategories
      .filter(category => category.rootFolderId === rootFolderId)
      .filter(item => item.showInEdit)
      .map(category => {
        return { label: category.title, value: category.id };
      });
    return _.iSortBy(options, 'label');
  }

  getDocumentTypeOptions(documentCategoryId) {
    const { documentSubCategoryId } = this.state;

    const options = this.props.documentTypes
      .filter(
        type =>
          type.documentCategoryId === documentCategoryId &&
          type.documentSubCategoryId === documentSubCategoryId &&
          type.showInEdit
      )
      .map(type => {
        let label = type.documentTitle;
        if (type.revisionNumber ) label = type.revisionNumber === ""|| type.revisionNumber=== " " ?`${label}`:`${label} - v${type.revisionNumber}`;
        return { label, value: type.id };
      });
      console.log(options);
    return _.iSortBy(options, 'label');
  }

  getSubCategoryOptions() {
    const { documentCategoryId } = this.state;
    const options = this.props.documentSubCategories
      .filter(sub => sub.documentCategoryId === documentCategoryId)
      .filter(item => item.showInEdit)
      .map(sub => ({ label: sub.title, value: sub.id }));
    return _.iSortBy(options, 'label');
  }

  setAutoCategory(rootFolderId) {
    const categories = this.getCategoryOptions(rootFolderId);
    if (categories.length === 1) {
      this.setState({ documentCategoryId: categories[0].value });
      getSas().then(this.checkSourceExists);
    }
  }

  handleChange(field) {
    return e => {
      this.setState({ [field]: e.target.value });
    };
  }

  handleSelectChange(field) {
    return option => {
      const value = option ? option.value : null;
      const newState = { [field]: value };
      this.setState(newState);

      if (field === 'documentCategoryId') {
        getSas().then(this.checkSourceExists);
        newState.isURA = this.props.uraCategoryId === parseInt(value, 10);
        newState.documentSubCategoryId = null;
        newState.documentTypeId = null;
      } else if (field === 'documentSubCategoryId') {
        newState.documentTypeId = null;
      } else if (field === 'displacementTypeId') {
        newState.documentFolderId = null;
        newState.documentSubFolderId = null;
      } else if (field === 'documentFolderId') {
        newState.documentSubFolderId = null;
      }
    };
  }

  handleCancel = () => {
    this.props.onCancel(this.props.file);
  };

  handleUpload = () => {
    this.setState({
      isLoading: true,
      progressText: 'uploading',
    });
    getSas().then(this.putToBlobStorage);
  };

  putToBlobStorage = sas => {
    const { file } = this.props;
    const { documentCategoryId } = this.state;
    const sourceUrl = this.getBlobStorageUrl(sas, documentCategoryId);
    const url = sourceUrl + sas.signature;

    // https://docs.microsoft.com/en-us/rest/api/storageservices/put-blob
    axios
      .put(url, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-blob-content-type': file.type,
          'x-ms-meta-uploadvia': 'SAS Demo',
        },
      })
      .then(this.postDocumentToAPI)
      .catch(this.notifyError);
  };

  postDocumentToAPI = resp => {
    const { keyId, file } = this.props;
    const sourceUrl = resp.config.url.split('?')[0];
    const originalFilename = file.name;
    const data = {
      keyId,
      sourceUrl,
      originalFilename,
      ..._.pick(this.state, [
        'documentTypeId',
        'documentCategoryId',
        'documentSubCategoryId',
        'rootFolderId',
        'refId',
        'tenantId',
        'displacementTypeId',
        'documentFolderId',
        'documentSubFolderId',
      ]),
    };
    this.setState({ progressText: 'saving' });
    postDocument(keyId, data, this.onSuccess, this.notifyError);
  };

  onSuccess = resp => {
    this.props.onCancel(this.props.file);
    this.props.onUploaded(resp.data);
  };

  notifyError = error => {
    let errorText = _.get(error, 'response.data.message');
    if (!errorText) errorText = ERRORS['upload'];

    this.setState({
      errorText,
      progressText: '',
      isLoading: false,
    });
  };

  renderExistingLink() {
    const { existingDocument: doc } = this.state;
    const { keyId, folderSlug } = this.props;
    const url = `/documents/${keyId}/${folderSlug}/view/${doc.id}`;
    return (
      <p>
        {' '}
        or{' '}
        <a href={url} target="_blank" rel="noopener noreferrer">
          click here
        </a>{' '}
        to see the matching file.
      </p>
    );
  }

  renderError(errorText) {
    const exists = errorText.indexOf('previously uploaded') !== -1;
    return (
      <div className="file-error">
        {errorText}
        {exists ? this.renderExistingLink() : null}
        <button onClick={this.handleCancel}>Close</button>
      </div>
    );
  }

  renderActions() {
    const { progressText, errorText, isLoading, checkingExists } = this.state;
    if (errorText) return null;

    if (isLoading) {
      return (
        <div className="doc-progress">
          <span>{progressText}</span>
          <button className="btn-load">
            <span className="loader" />
          </button>
        </div>
      );
    }

    const isValid = this.isValid();
    return (
      <div className="actions">
        <button
          className="btn-primary"
          disabled={!isValid || checkingExists}
          onClick={this.handleUpload}
        >
          {checkingExists ? 'Checking' : 'Upload'}
        </button>
        <button className="btn-cancel" onClick={this.handleCancel}>
          Cancel
        </button>
      </div>
    );
  }

  renderTenantField() {
    const { tenantId } = this.state;
    return (
      <div className="dynamic-field">
        <label htmlFor="tenantId">Tenant ID</label>
        <Input
          name="tenantId"
          value={tenantId}
          onChange={this.handleChange('tenantId')}
        />
      </div>
    );
  }

  getTooltip(options, id) {
    if (options.length === 0) return '';
    return _.get(_.findBy('value', options, id), 'label', '');
  }

  renderDisplacementTypeField() {
    const { displacementTypeId: value } = this.state;
    const options = mapTitles(this.props.uraTypes);
    const tooltip = this.getTooltip(options, value);

    return (
      <div className="dynamic-field">
        <label htmlFor="displacementTypeId">Displacement Type</label>
        <TSelect
          name="displacementTypeId"
          value={value}
          tooltip={tooltip}
          options={options}
          onChange={this.handleSelectChange('displacementTypeId')}
        />
      </div>
    );
  }

  renderURAFolderField() {
    const { documentFolderId: value } = this.state;
    const options = this.getDocumentFolderOptions();
    const tooltip = this.getTooltip(options, value);

    return (
      <div className="dynamic-field">
        <label htmlFor="documentFolderId">URA Folder</label>
        <TSelect
          name="documentFolderId"
          value={value}
          tooltip={tooltip}
          options={this.getDocumentFolderOptions()}
          onChange={this.handleSelectChange('documentFolderId')}
        />
      </div>
    );
  }

  renderURASubFolderField() {
    const { documentSubFolderId: value } = this.state;
    const options = this.getDocumentSubFolderOptions();
    const tooltip = this.getTooltip(options, value);

    return (
      <div className="dynamic-field">
        <label htmlFor="documentSubFolderId">URA Sub-Folder</label>
        <TSelect
          name="documentSubFolderId"
          value={value}
          tooltip={tooltip}
          options={options}
          onChange={this.handleSelectChange('documentSubFolderId')}
        />
      </div>
    );
  }

  renderSubCategoryField() {
    const options = this.getSubCategoryOptions();
    if (!options.length) return null;

    const { documentSubCategoryId: value } = this.state;
    const tooltip = this.getTooltip(options, value);

    return (
      <div className="double-field">
        <label htmlFor="documentSubCategoryId">Sub Category</label>
        <TSelect
          name="documentSubCategoryId"
          value={value}
          tooltip={tooltip}
          options={options}
          onChange={this.handleSelectChange('documentSubCategoryId')}
        />
      </div>
    );
  }

  renderRefIdField() {
    const { refId, refIdOptions: options } = this.props;
    const value = this.state.refId;

    return refId ? (
      <Input
        name="refId"
        value={value}
        readOnly={refId}
        onChange={this.handleChange('refId')}
      />
    ) : (
      <TSelect
        name="documentSubCategoryId"
        value={value}
        tooltip={this.getTooltip(options)}
        options={options}
        onChange={this.handleSelectChange('refId')}
      />
    );
  }

  render() {
    const {
      documentName,
      documentCategoryId,
      rootFolderId,
      rootFolderTitle,
      documentTypeId,
      errorText,
      isURA,
    } = this.state;

    if (errorText) return this.renderError(errorText);

    const categoryOptions = this.getCategoryOptions(rootFolderId);
    const docTypeOptions = this.getDocumentTypeOptions(documentCategoryId);
    const categoryTip = this.getTooltip(categoryOptions, documentCategoryId);
    const docTypeTip = this.getTooltip(docTypeOptions, documentTypeId);

    return (
      <div className="document-form">
        <div className="control">
          <label htmlFor="documentName">Name</label>
          <Input name="documentName" readOnly={true} value={documentName} />
          {isURA ? this.renderTenantField() : null}
        </div>
        <div className="control">
          <label htmlFor="rootFolderId">Library</label>
          <Input name="rootFolderId" readOnly={true} value={rootFolderTitle} />
          {isURA ? this.renderDisplacementTypeField() : null}
        </div>
        <div className="control">
          <label htmlFor="documentCategoryId">Category</label>
          <TSelect
            name="documentCategoryId"
            value={documentCategoryId}
            tooltip={categoryTip}
            options={categoryOptions}
            onChange={this.handleSelectChange('documentCategoryId')}
          />
          {this.renderSubCategoryField(documentCategoryId)}
        </div>
        <div className="control">
          <label htmlFor="documentTypeId">DocumentType</label>
          <TSelect
            name="documentTypeId"
            value={documentTypeId}
            tooltip={docTypeTip}
            options={docTypeOptions}
            onChange={this.handleSelectChange('documentTypeId')}
          />
          {isURA ? this.renderURAFolderField() : null}
        </div>
        <div className="control refid-control">
          <label htmlFor="refId">{this.props.refIdDisplayTitle}</label>
          {this.renderRefIdField()}
          {isURA ? this.renderURASubFolderField() : null}
        </div>
        {this.renderActions()}
      </div>
    );
  }
}

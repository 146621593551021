export default {
  exists:
    'A file with this name was previously uploaded into this HRO library. ' +
    'You can change the name of the file and try again',
  size:
    'The file you are attempting to upload exceeds 300MB in size and needs to be reduced. ' +
    'If it cannot be reduced, please contact your HRO system support for assistance.',
  extension:
    'The file you are attempting to upload has an unsupported extension. ' +
    'We support PDF, Word, Excel, Powerpoint, Visio, JPG, PNG, TIFF, MSG, ESX and TXT formats at this time.',
  prompt:
    'You have uploaded content that hasn\'t been indexed yet, ' +
    'are you sure you want to leave this screen?',
  refIdPrompt:
    'Are you sure you want to leave this screen without uploading and indexing content?',
  required: 'This field cannot be empty',
  upload:
    'There seems to be an error uploading this document. If this keeps happening, ' +
    'please contact your HRO system support for assistance.',
  noUploadPermissions:
    'Sorry, you currently do not have permission to upload documents to this library.',
  noViewPermissions:
    'Sorry, you currently do not have permissions to view or download this document.',
  noEditPermissions:
    'Sorry, you currently do not have permissions to edit this document.',
  noUploadInitiate: 'Please initiate the first upload from CMS.',
  uploadsDisabled: 'Uploads to this folder are disabled by the administrator.',
  alreadyClassified:
    'This document has already been classified.  Further changes are not allowed.',
  genericEdit: 'There seems to be an unknown error while trying to save.',
  genericDelete: 'There seems to be an unknown error while trying to delete.',
  deleteSuccess: 'The document has been successfully deleted',
  previewGenerationFailed:
    'This document is in an unsupported format and cannot be previewed. ' +
    'The file may be an archive or password protected or in a non-viewable format. ' +
    'Please download the file and use a desktop viewer to review.',
  notActive:
    'The application is no longer active', 
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setLocal } from 'utils/storage';
import { setClaims } from 'actions/homeActions';

const WHITELIST = [
  '/pages/logout',
  '/redirect',
]

const Loading = () => (
  <div className="loading">
    <span className="loader"/>
  </div>
)


class ScrollToTop extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    statusCode: PropTypes.number.isRequired,
  }


  componentDidMount() {
    const { pathname } = this.props.location
    if (pathname.indexOf('/pages/') !== 0) setClaims();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
      this.setLastSearchPath();
    }
    this.setLastListPath(prevProps.location);
  }

  setLastSearchPath () {
    const { pathname } = this.props.location;
    if (pathname.indexOf('/documents/') !== 0) {
      setLocal('lastSearchPath', pathname);
    }
  }

  setLastListPath ({ pathname }) {
    const isDetail = pathname.indexOf('/view/') !== -1;
    const isEdit = pathname.indexOf('/edit/') !== -1;
    if (!isDetail && !isEdit) {
      window.lastListPath = pathname
    }
  }

  render() {
    const { location, statusCode } = this.props;
    const inWhitelist = WHITELIST.indexOf(location.pathname) !== -1;
    return (statusCode || inWhitelist)
      ? this.props.children
      : <Loading/>;
  }
}

function mapStateToProps (state) {
  return {
    statusCode: state.homeStore.statusCode
  }
}

export default connect(mapStateToProps)(withRouter(ScrollToTop));

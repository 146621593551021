import React from 'react';
import ReactDOM from 'react-dom';
import UserConfirmation from './UserConfirmation'


const getUserConfirmation = (message, callback) => {
  const modal = document.createElement('div')
  document.body.appendChild(modal)

  const withCleanup = (answer) => {
    ReactDOM.unmountComponentAtNode(modal)
    document.body.removeChild(modal)
    callback(answer)
  }

  ReactDOM.render(
    <UserConfirmation
      message={message}
      onCancel={() => withCleanup(false)}
      onConfirm={() => withCleanup(true)}
    />,
    modal
  )
}

export default getUserConfirmation;

import React from 'react';
import PropTypes from 'prop-types';


class UserConfirmation extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  render () {
    const { message, onConfirm, onCancel } = this.props;

    return (
      <div className="modal prompt-modal">
        <div className="modal-fade"/>
        <div className="modal-content">
          <p>{message}</p>
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    )
  }

}

export default UserConfirmation;

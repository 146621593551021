import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApplicationInfo } from 'actions/appsActions';
import _ from 'utils/fp';
import IconButton from 'components/global/IconButton';


const useResize = (myRef) => {
    const [width,setWidth] = useState(0);
    const handleResize =() =>{
        setWidth(myRef.current.offsetWidth);
    }
    useEffect(()=>{
        handleResize();
        window.addEventListener('resize', handleResize)

        return () => {
          window.removeEventListener('resize', handleResize)
        }
      }, [myRef.current]);
    
    return { width}
}
const ColumnComponent = (props) => {
    const columnRef = useRef();
    const {width}  = useResize(columnRef);
    let returnColSpacing = (keyName) => {
        switch (keyName) {
            case 'Applicant Name':
                return 'col m-1-and-half l2 hide-on-small app-detail-center-align';
            case 'Status':
                return 'col m1 l-half-1 hide-on-small hide-on-md app-detail-center-align';
            case 'Status Reason':
                return 'col m1 l1 hide-on-small hide-on-md app-detail-center-align';
            case 'Managing Agency':
                return 'col  m-1-and-half l1  hide-on-small app-detail-center-align';
            case 'Pathway Selected':
                return 'col m2 l2  hide-on-small app-detail-center-align';
            case 'Home ID':
                return 'col  m-1-and-half  l2 hide-on-small app-detail-center-align';
            default:
                return '';
        }
    }
    let  getTextWidth = (text, font)=> {
        // re-use canvas object for better performance
        let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width;
    }
    let wordsLargerthanSpace = (widthinPixels,words)=>{
        const wordWidthinPixels = getTextWidth(words,"15px proxima-nova");
        const equalChars = Math.floor(widthinPixels* (words.length/wordWidthinPixels));
        

        return {
            overLimit: words.length >= equalChars,
            charsCountofDiv: equalChars
        }

    }

    const {overLimit,charsCountofDiv} = wordsLargerthanSpace(width,props.value);
    let reducedValue = overLimit ? `${props.value.substring(0, charsCountofDiv-10)}...` : props.value;
    return (
        <div ref={columnRef} className={returnColSpacing(props.title)}>
            <div className='row'>
                <span className="header-label">{props.title}</span>
            </div>
            <div className='row'>
                <span className="app-detail-value">{reducedValue}</span>
                {
                    overLimit &&
                    <div className="icon-popover-wrapper">
                    <IconButton
                        icon="Info"
                        title="More Information"
                        className="btn-icon"
                    />
                    <div className='popover'>
                        <span>{props.value}</span>
                    </div>
                    </div>
                    
                }
            </div>
        </div>
    );
}
const ApplicationInfoHeader = (props) => {
    const valuesToDisplay = ['applicant', 'homeId', 'pathwaySelected', 'managingAgency', 'status', 'statusReason'];
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchApplicationInfo(props.keyId));
    }, [props.keyId, dispatch])
    let returnTitles = (keyName) => {
        switch (keyName) {
            case 'applicant':
                return 'Applicant Name';
            case 'status':
                return 'Status';
            case 'statusReason':
                return 'Status Reason';
            case 'managingAgency':
                return 'Managing Agency';
            case 'pathwaySelected':
                return 'Pathway Selected';
            case 'homeId':
                return 'Home ID';
            default:
                return '';
        }
    }
    const applicatonInfo = _.pick(useSelector(state => state.appStore.applicationInfo), valuesToDisplay);
    let seriesOfcolumns = Object.keys(applicatonInfo).map((key) => {
        return (

            <ColumnComponent
                title={returnTitles(key)}
                value={!!applicatonInfo[key] ? applicatonInfo[key] : ''}
            />

        )
    })
    let defaultBar =  valuesToDisplay.map((title)=>{
        return (
            <ColumnComponent
                title={returnTitles(title)}
                value={''}
            />
        )})

    const columnsToRender = seriesOfcolumns.length < 1 ? defaultBar : seriesOfcolumns;
  
    return (
        <>
            {columnsToRender}
        </>
    )

}

export default ApplicationInfoHeader;
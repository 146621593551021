import React from 'react';
import PropTypes from 'prop-types';

import _ from 'utils/fp';
import UserDateData from './UserDateData';
import { isDocURA, isDocPDR } from 'utils/documents';

export default class Metadata extends React.Component {
  static propTypes = {
    doc: PropTypes.object,
    privileges: PropTypes.object.isRequired,
  };

  renderDocumentType(doc) {
    const isManual = doc.documentCategory.slug === 'manual-classification';
    const documentType = doc.documentType
      ? doc.documentType.documentTitle
      : '-';
    return (
      <div className="control">
        <label>{(isManual ? 'Parent ' : '') + 'Document Type'}</label>
        <div>{isManual ? doc.parentFileDocumentType : documentType}</div>
      </div>
    );
  }

  getDetailUrl(src) {
    const { keyId } = this.props.doc;
    return `/documents/${keyId}/${src.rootFolder.slug}/view/${src.id}/`;
  }

  renderParentTitle(doc) {
    if (doc.documentCategory.slug !== 'manual-classification') return null;
    return (
      <div className="control">
        <label>Parent Document Title</label>
        <div>{doc.parentFileTitle}</div>
      </div>
    );
  }

  renderParentFilename(doc) {
    if (!doc.parentFilePath) return null;
    return (
      <div className="control">
        <label>Parent File Name</label>
        <div>{_.last(doc.parentFilePath.split('/'))}</div>
      </div>
    );
  }

  renderURAFields(doc) {
    return (
      <div>
        <div className="control">
          <label>Tenant ID</label>
          <div>{doc.tenantId}</div>
        </div>
        <div className="control">
          <label>Displacement Type</label>
          <div>{doc.displacementType ? doc.displacementType.title : null}</div>
        </div>
        <div className="control">
          <label>URA Folder</label>
          <div>{doc.documentFolder ? doc.documentFolder.title : null}</div>
        </div>
        <div className="control">
          <label>URA Sub-Folder</label>
          <div>
            {doc.documentSubFolder ? doc.documentSubFolder.title : null}
          </div>
        </div>
      </div>
    );
  }

  renderPDRFields(doc) {
    return (
      <div>
        <div className="control">
          <label>Applicant Name</label>
          <div>{doc.applicantName || '-'}</div>
        </div>
        <div className="control">
          <label>Document Description</label>
          <div>{doc.documentDescription || '-'}</div>
        </div>
      </div>
    );
  }

  renderRefId(doc, privileges) {
    if (doc.rootFolder.slug === 'application' && !doc.refId) return;
    return (
      <div className="control">
        <label>{privileges.refIdDisplayTitle}</label>
        <div>{doc.refId || '-'}</div>
      </div>
    );
  }

  renderOriginalFiles(doc) {
    const srcDocuments = [];
    if (doc.isSplitResult && doc.splitSourceDocument) {
      srcDocuments.push(doc.splitSourceDocument);
    } else if (doc.isMergeResult && doc.mergeSourceDocuments) {
      srcDocuments.push(...doc.mergeSourceDocuments);
    }

    if (srcDocuments.length === 0) return null;

    return (
      <div className="control">
        <label>Source File{srcDocuments.length && 's'}</label>
        {srcDocuments.map(src => (
          <a
            key={src.id}
            href={this.getDetailUrl(src)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {src.documentName}
          </a>
        ))}
      </div>
    );
  }

  render() {
    const { doc, privileges } = this.props;
    const isURA = isDocURA(doc);
    const isPDR = isDocPDR(doc);

    return (
      <div>
        <h6>Details</h6>
        <div className="control">
          <label>Library</label>
          <div>{doc.rootFolder.title}</div>
        </div>
        <div className="control">
          <label>Category</label>
          <div>{_.get(doc, 'documentCategory.title', '-')}</div>
        </div>
        <div className="control">
          <label>Sub Category</label>
          <div>{_.get(doc, 'documentSubCategory.title', '-')}</div>
        </div>
        {this.renderDocumentType(doc)}
        {this.renderParentTitle(doc)}
        {this.renderParentFilename(doc)}
        {isURA ? this.renderURAFields(doc) : null}
        {isPDR ? this.renderPDRFields(doc) : null}
        {this.renderRefId(doc, privileges)}
        {this.renderOriginalFiles(doc)}
        <UserDateData doc={doc} />
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import ERRORS from './errors';
import { generatePreview } from 'actions/appsActions';
import { getSas } from 'actions/sas';
import DocumentPages from './DocumentPages';
import { MAX_RENDER_MB } from 'utils/limits';

const MIN_HEIGHT = 600;

const STOPPED_TEXT =
  'We\'re going to need more time to generate this preview, ' +
  'please come back later or download the original file via the download icon.';
const TOO_LARGE_RENDER = 
  'The file you are trying to preview is larger than 1.5 GB, please download the file to view.';

const isWaiting = status => status === 'notAvailable' || status === 'queued';

export const isTooLargeTooRender =(fileSizeinBytes,limitInMb=MAX_RENDER_MB)=>{return fileSizeinBytes*Math.pow(10,-6) > limitInMb;}

export default class DocumentPreview extends React.Component {
  static propTypes = {
    document: PropTypes.object.isRequired,
    splitStart: PropTypes.number,
    splitEnd: PropTypes.number,
    mergeIds: PropTypes.arrayOf(PropTypes.number),
    onPageChange: PropTypes.func, // reorder/rotate mode
  };

  static defaultProps = {
    splitStart: undefined,
    splitEnd: undefined,
    mergeIds: undefined,
    onPageChange: undefined,
  };

  constructor(props) {
    super(props);
    const {
      previewBlobUrl,
      previewStatus,
      isDocumentStillInProcess,
      fileSizeBytes,
      printBlobUrl,
      pageCount
    } = props.document;
    this.state = {
      isLoading: false,
      iframeHeight: MIN_HEIGHT,
      previewStatus,
      previewBlobUrl,
      isDocumentStillInProcess,
      fileSizeBytes,
      printBlobUrl,
      pageCount,
      isPrintPreview:false,
    };
    this.timeout = null;
    this.retryCount = 0;
  }

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.setHeight, 0);
    window.addEventListener('resize', this.setHeight);
    if (!this.isPagesView()) this.processPreview();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.timeout) clearTimeout(this.timeout);
    window.removeEventListener('resize', this.setHeight, true);
  }

  setPreview({ previewStatus,pageCount, previewBlobUrl,isDocumentStillInProcess }) {
    getSas().then(() => {
      if (!this._isMounted) return;
      this.setState({ previewStatus,pageCount,previewBlobUrl,isDocumentStillInProcess });
    });
  }

  setHeight = () => {
    if (!this._isMounted) return false;
    const windowWidth = window.innerWidth;
    const avlHeight = window.innerHeight - 180;
    const height = windowWidth > 768 ? avlHeight : windowWidth;
    const iframeHeight = Math.max(height, MIN_HEIGHT);
    this.setState({ iframeHeight });
  };

  retryProcessPreview = preview => {
    if (this.retryCount < 3) {
      this.retryCount += 1;
      this.setPreview(preview);
      this.timeout = setTimeout(this.processPreview, 4000);
    } else {
      this.setState({ previewStatus: 'stopped' });
    }
  };

  processPreview = () => {
    const { previewStatus } = this.state;
    console.log(this.props)
    if (!isWaiting(previewStatus)) {
      return this.setPreview(this.state);
      
    }

    generatePreview(
      this.props.document,
      this.retryProcessPreview,
      this.notifyError
    );
  };

  notifyError = () => {
    this.setState({ previewStatus: 'generationFailed' });
  };

  print() {
    this.setState({isPrintPreview:true})
    
    this.frame.contentWindow.focus();
    this.frame.contentWindow.print();
    
    this.setState({isPrintPreview:false})
  }
  isPagesView() {
    const { splitStart, mergeIds, onPageChange } = this.props;
    return splitStart || mergeIds || onPageChange;
  }

  renderLoader() {
    return (
      <div>
        <p>Preview is being generated</p>
        <div className="btn-load">
          <span className="loader" />
        </div>
      </div>
    );
  }

  renderPreviewError(style, message) {
    return (
      <div className="preview-box" style={style}>
        <div className="preview-status">{message}</div>
      </div>
    );
  }
  updateParent(){
    if(!!this.props.notifyParent){
      this.props.notifyParent({
        pageCount: this.state.pageCount, 
        isDocumentStillInProcess: this.state.isDocumentStillInProcess});
    }
  }
  renderPdf() {
    const {
      previewStatus,
      previewBlobUrl,
      iframeHeight,
      isDocumentStillInProcess,
      fileSizeBytes,
      printBlobUrl,
      isPrintPreview
    } = this.state;
    const style = { height: iframeHeight + 'px' };
    if(isTooLargeTooRender(fileSizeBytes)){
      return this.renderPreviewError(style,TOO_LARGE_RENDER);
    }else if (previewStatus === 'stopped') {
      return this.renderPreviewError(style, STOPPED_TEXT);
    } else if (isWaiting(previewStatus)||isDocumentStillInProcess) {
      return this.renderPreviewError(style, this.renderLoader());
    } else if (previewStatus === 'generationFailed') {
      return this.renderPreviewError(style, ERRORS.previewGenerationFailed);
    }else{
      let viewer = `/pdfjs/web/viewer.html?file=${encodeURIComponent(previewBlobUrl)}`;
      let printURL = `/pdfjs/web/viewer.html?file=${encodeURIComponent(printBlobUrl)}`
      return (
        <div>
          <iframe
            ref={el => (this.frame = el)}
            className="pdfjs-view"
            style={style}
            title="PDF Preview"
            onLoad={this.updateParent.bind(this)}
            src={isPrintPreview ? printURL:viewer}
            allowFullScreen
          />
        </div>
      );
    }
  }

  renderPages() {
    const {
      document,
      mergeIds,
      splitStart,
      splitEnd,
      onPageChange,
    } = this.props;
    const { iframeHeight } = this.state;

    const documentIds = mergeIds || [document.id];
    return (
      <div  style={{ height: iframeHeight,overflowY:'scroll'}}>
        {documentIds.map(id => (
          <DocumentPages
            key={id}
            keyId={document.keyId}
            documentId={id}
            startPage={splitStart}
            endPage={splitEnd}
            onPageChange={onPageChange}
          />
        ))}
      </div>
    );
  }

  render() {
    return this.isPagesView() ? this.renderPages() : this.renderPdf();
  }
}

import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'utils/fp';

const ColumnSelector = ({ columns, hiddenCols, onDone, onCancel }) => {
  const [inactive, setInactive] = useState(hiddenCols);
  const wrapper = useRef(null);

  const handleClick = useCallback(
    e => {
      if (wrapper.current && !wrapper.current.contains(e.target)) {
        onCancel();
      }
    },
    [onCancel]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  function toggleColumn(index) {
    setInactive(_.toggleArrayItem(inactive, index));
  }

  return (
    <div className="column-names" ref={wrapper}>
      {columns.map((col, i) =>
        col.length > 0 ? (
          <span key={i} onClick={() => toggleColumn(i)}>
            <i>{inactive.includes(i) ? ' ' : '✓'}</i>
            {col}
          </span>
        ) : null
      )}
      <button className="btn-primary" onClick={() => onDone(inactive)}>
        Done
      </button>
    </div>
  );
};

ColumnSelector.propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenCols: PropTypes.array.isRequired,
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ColumnSelector;

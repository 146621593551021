import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchWatchListStatus } from 'actions/appsActions';

import { changeWatch, updateWatchIn } from 'actions/homeActions';
import IconButton from 'components/global/IconButton';


class AppIdWatch extends React.Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    inWatchList: PropTypes.bool.isRequired,
    fetchWatchListStatus: PropTypes.func.isRequired,
    updateWatchIn: PropTypes.func.isRequired,
  }

  componentDidMount () {
    this.props.fetchWatchListStatus(this.props.keyId);
  }

  changeWatchFor = () => {
    const { inWatchList, keyId } = this.props;
    changeWatch(inWatchList, keyId, () => {
      this.props.updateWatchIn('APPID', keyId, !inWatchList);
    });
  }

  render() {
    const { inWatchList } = this.props;
    const btnClass = 'btn-action' + (inWatchList ? ' active' : '');
    const title =  inWatchList ? 'Unwatch' : 'Watch';
    return (
      <IconButton
        icon="Watchlist"
        title={title}
        className={btnClass}
        onClick={this.changeWatchFor}
      />
    )
  }
}


function mapStateToProps (state) {
  return {
    inWatchList: state.appStore.inWatchList,
  }
}

export default connect(mapStateToProps, {
  fetchWatchListStatus,
  updateWatchIn,
})(AppIdWatch);

import get from 'lodash/get';
import set from 'lodash/set';
import last from 'lodash/last';
import every from 'lodash/every';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

function findBy(key, items, value) {
  for (const item of items) {
    if (item[key] === value) return item;
  }
  return null;
}

function findById(items, query) {
  return findBy('id', items, parseInt(query, 10));
}

// Case insensitive sorting by a field
function iSortBy(items, field) {
  return sortBy(items, item => item[field].toLowerCase());
}

function toggleArrayItem(items, item) {
  return items.includes(item)
    ? items.filter(i => i !== item)
    : [...items, item];
}

export default {
  get,
  set,
  last,
  every,
  pick,
  omit,
  orderBy,
  sortBy,
  iSortBy,
  uniq,
  findBy,
  findById,
  isEmpty,
  toggleArrayItem,
};

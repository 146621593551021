import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchMetrics } from 'actions/homeActions';


class Metrics extends React.Component {
  static propTypes = {
    metrics: PropTypes.object.isRequired,
    fetchMetrics: PropTypes.func.isRequired,
  }

  componentDidMount () {
    this.props.fetchMetrics();
  }

  render () {
    const { metrics } = this.props;

    return (
      <div className="row no-gutters metrics">
        <div className="col-fifth">
          <div className="metric-box">
            <span>APP IDs</span>
            <i className="bar"></i>
            <strong>{metrics.totalKeyIds.toLocaleString()}</strong>
          </div>
        </div>
        <div className="col-fifth">
          <div className="metric-box">
            <span>DOCUMENTS</span>
            <i className="bar"></i>
            <strong>{metrics.totalProcessedDocuments.toLocaleString()}</strong>
          </div>
        </div>
        <div className="col-fifth">
          <div className="metric-box has-more">
            <span>DOCUMENTS BY TYPE</span>
            {metrics.mostUsedDocumentTypes.slice(0, 7).map((type, index) => {
              return (
                <div key={index}>
                  <small>{type.key}</small>
                  <small>{type.value.toLocaleString()}</small>
                </div>
              )
            })}
          </div>
        </div>
        <div className="col-fifth">
          <div className="metric-box">
            <span>VIEWS</span>
            <i className="bar"></i>
            <strong>{metrics.totalDocumentViews.toLocaleString()}</strong>
          </div>
        </div>
        <div className="col-fifth">
          <div className="metric-box">
            <span>SEARCHES</span>
            <i className="bar"></i>
            <strong>{metrics.totalSearchPast30Days.toLocaleString()}</strong>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    metrics: state.homeStore.metrics
  }
}

export default connect(mapStateToProps, {
  fetchMetrics,
})(Metrics);

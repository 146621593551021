import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

import { fetchCategories } from 'actions/appsActions';
import { DOCUMENT_TITLE } from 'utils';

const menuList = (
  <TextBlock
    rows={12}
    color="#ddd"
    lineSpacing={'1.3125rem'}
    widths={[90, 80, 70, 60, 90, 70, 98, 99]}
  />
);

const Placeholder = ({ ready, children }) => (
  <ReactPlaceholder
    showLoadingAnimation
    customPlaceholder={menuList}
    ready={ready}
  >
    {children}
  </ReactPlaceholder>
);

Placeholder.propTypes = {
  children: PropTypes.object.isRequired,
  ready: PropTypes.bool.isRequired,
};

class SideMenu extends React.Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    folderSlug: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    privileges: PropTypes.object.isRequired,
    classifiedCategories: PropTypes.array.isRequired,
    relatedCategories: PropTypes.array.isRequired,

    onPermissionError: PropTypes.func.isRequired,
    toggleSideMenu: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { keyId } = this.props;
    this.props.fetchCategories(keyId);
    this.setDocumentTitle();
  }

  componentWillUnmount() {
    document.title = 'HRO DMS';
  }

  componentDidUpdate() {
    this.setDocumentTitle();
  }

  setDocumentTitle = () => {
    const { keyId } = this.props;
    document.title = `${DOCUMENT_TITLE} | ${keyId.toUpperCase()}`;
  };

  notifyPermission = () => {
    const errorText = 'You don\'t have permissions to view this library';
    this.props.onPermissionError(errorText);
  };

  renderCategories(categories) {
    const { keyId, folderSlug, query } = this.props;
    const unique_name = [...new Set(categories.map(obj => {
      return obj.type.id
    }))].filter((id)=> id !== 31).map((id) => {
      return categories.find(value => (value.type.id === id ));
    })

    // const unique_name_temp = [...new Set(categories.map(obj => {
    //   return obj.type.id
    // }))].filter((id)=> id !== 31);

    // console.log(unique_name_temp);

    const links = unique_name.map(({ count, type, rootFolder }) => {
      const url = rootFolder
        ? `/documents/${keyId}/${rootFolder}/${type.slug}`
        : `/documents/${keyId}/${type.slug}`;
      const linkText = `${type.title} (${count})`;

      const isActive = rootFolder
        ? `${folderSlug}-${query}` === `${rootFolder}-${type.slug}`
        : folderSlug === type.slug;
      const classes = [count === 0 ? 'zero' : '', isActive ? 'active' : ''];

      return (
        <li key={type.id} className={classes.join(' ')}>
          {type.privileges.canView ? <Link to={url}>{linkText}</Link> : null}
        </li>
      );
    });

    return <ul>{links}</ul>;
  }

  render() {
    const { classifiedCategories, relatedCategories } = this.props;
    const ready = classifiedCategories.length > 0;
    // console.log(classifiedCategories)
    return (
      <div className="side-menu">
        <button
          className="close"
          onClick={() => this.props.toggleSideMenu(false)}
        >
          <span className="svg-Icon-Close" />
        </button>
        <div className="sub-menu">
          <h4>Application Documents</h4>
          <Placeholder ready={ready}>
            {this.renderCategories(classifiedCategories)}
          </Placeholder>
        </div>

        <div className="sub-menu">
          <h4>Related Documents</h4>
          <Placeholder ready={ready}>
            {this.renderCategories(relatedCategories)}
          </Placeholder>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    privileges: state.appStore.privileges,
    classifiedCategories: state.appStore.classifiedCategories,
    relatedCategories: state.appStore.relatedCategories,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchCategories,
  }
)(SideMenu);
